const BASE_URL = 'https://zulushop.in';

export const fetchProducts = async () => {
  try {
    const response = await fetch(`${BASE_URL}/app/v1/api/get_products`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}) // Empty object as body if no parameters are required
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('API Response:', data); // For debugging

    if (data.error) {
      throw new Error(data.message || 'Failed to fetch products');
    }

    return data.data || [];
  } catch (error) {
    console.error('Error in fetchProducts:', error);
    throw new Error('Failed to fetch products. Please try again later.');
  }
};

export const fetchCategories = async () => {
  try {
    // Create FormData object
    const formData = new FormData();
    formData.append('ci_csrf_token', ''); // Adding empty token as required by the API

    const response = await fetch(`${BASE_URL}/zulu_category_list`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        // Remove Content-Type header to let the browser set it automatically with boundary
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Categories API Response:', data); // Debug log

    // Handle different response structures
    if (Array.isArray(data)) {
      return data;
    } else if (data.categories) {
      return data.categories;
    } else if (data.data) {
      return data.data;
    }

    throw new Error('Invalid response format');
  } catch (error) {
    console.error('Error in fetchCategories:', error);
    throw new Error('Failed to fetch categories. Please try again later.');
  }
};

export const fetchSpecificProducts = async () => {
  try {
    // Create FormData object
    const formData = new FormData();
    formData.append('seller_id', '467');
    formData.append('sub_sub_category_id', '1916');

    const response = await fetch(`${BASE_URL}/app/v1/api/get_products`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        // Remove Content-Type header to let the browser set it automatically with boundary
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Products API Response:', data);

    if (data.error) {
      throw new Error(data.message || 'Failed to fetch products');
    }

    return data.data || [];
  } catch (error) {
    console.error('Error in fetchSpecificProducts:', error);
    throw new Error('Failed to fetch products. Please try again later.');
  }
};

export const fetchSellers = async () => {
  try {
    const response = await fetch(`${BASE_URL}/app/v1/api/get_sellers`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Sellers API Response:', data);

    if (data.error) {
      throw new Error(data.message || 'Failed to fetch sellers');
    }

    return data.data || [];
  } catch (error) {
    console.error('Error in fetchSellers:', error);
    throw new Error('Failed to fetch sellers. Please try again later.');
  }
};

export const fetchProductById = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/product/${id}`);
    if (!response.ok) throw new Error('Failed to fetch product');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching product:', error);
    throw error;
  }
};