import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      <Navbar />
      
      {/* Hero Section */}
      <div className="bg-gradient-to-b from-gray-900 to-black">
        <div className="container mx-auto px-4 py-12 sm:py-16 md:py-20 lg:py-24">
          <div className="mt-[20%] xl:mt-[10%] 2xl:mt-[5%]">
            <h1 className="text-3xl sm:text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl font-bold text-center mb-4 sm:mb-6">
              Terms & Conditions
            </h1>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-400 text-center max-w-xl sm:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl mx-auto">
              Please read these terms and conditions carefully before using our services
            </p>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 md:py-16">
        <div className="max-w-xl sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl mx-auto space-y-8 sm:space-y-12">
          {/* Last Updated */}
          <p className="text-xs sm:text-sm md:text-base text-gray-400">Last Updated: February 22, 2025</p>

          {/* Introduction */}
          <section className="space-y-3 sm:space-y-4">
            <h2 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold text-white/90">
              1. Introduction
            </h2>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
            Welcome to Zulu Club! These Terms and Conditions ("Terms") govern your use of the Zulu Club mobile application ("App") and website ("Platform"), which enables users to shop from various malls online. By accessing or using our services, you agree to be bound by these Terms.

            </p>
          </section>

          {/* Services */}
          <section className="space-y-3 sm:space-y-4">
            <h2 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold text-white/90">
              2. Acceptance of Terms

            </h2>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
            By using Zulu Club, you acknowledge that you have read, understood, and agreed to comply with these Terms. If you do not agree, please do not use our services.

            </p>
          </section>

          {/* User Responsibilities */}
          <section className="space-y-3 sm:space-y-4">
            <h2 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold text-white/90">
              3. Account Registration
            </h2>
            <div className="space-y-3">
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
            To shop via Zulu Club, you must create an account with accurate and complete information.

You are responsible for maintaining the confidentiality of your account credentials.

We reserve the right to suspend or terminate accounts that provide false or misleading information.


            </p>
              
            </div>
          </section>

          {/* Privacy */}
          <section className="space-y-3 sm:space-y-4">
            <h2 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold text-white/90">
              4. Shopping and Orders

            </h2>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
            Zulu Club provides a platform for users to shop from partnered malls; we do not own or directly sell the products.

Orders placed through the app are subject to availability, and we do not guarantee stock or delivery times.

Prices, discounts, and offers are determined by individual mall vendors and may change without prior notice.

            </p>
          </section>

          {/* Limitations */}
          <section className="space-y-3 sm:space-y-4">
            <h2 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold text-white/90">
              5. ⁠Payments and Transactions

            </h2>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
            Payments are processed securely through third-party payment providers.

Zulu Club is not responsible for any transaction failures, payment gateway issues, or unauthorized transactions.

Refunds and returns are subject to the policies of the respective mall vendors.

            </p>
          </section>

          {/* Shipping and Delivery */}
          <section id="shipping-and-delivery" className="space-y-3 sm:space-y-4">
            <h2 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold text-white/90">
              6. Shipping and Delivery
            </h2>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
            Delivery times vary depending on the vendor and location.

Zulu Club is not liable for delays, lost shipments, or damages during transit.

Users must provide accurate shipping details; incorrect information may lead to delays or cancellations.

            </p>
          </section>

          {/* Returns and Refunds */}
          <section id="returns-and-refunds" className="space-y-3 sm:space-y-4">
            <h2 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold text-white/90">
              7. Returns and Refunds
            </h2>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
            Return and refund policies are determined by the individual mall vendors.

Users should check vendor policies before making a purchase.

If a refund is approved, processing times may vary depending on the payment provider.

            </p>
          </section>

          {/* User Conduct */}
          <section className="space-y-3 sm:space-y-4">
            <h2 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold text-white/90">
              8. User Conduct


            </h2>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
            By using our platform, you agree not to:

Engage in fraudulent or misleading transactions.

Use the platform for illegal or unauthorized purposes.

Upload harmful software, viruses, or content that disrupts platform operations.

Violate intellectual property rights of Zulu Club or its vendors.



            </p>
          </section>

          {/* Intellectual Property */}
          <section className="space-y-3 sm:space-y-4">
            <h2 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold text-white/90">
              9.  ⁠Intellectual Property


            </h2>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
            All content, logos, and trademarks on Zulu Club are owned by or licensed to us.

You may not copy, distribute, or modify any content without prior written permission.



            </p>
          </section>

          {/* Limitation of Liability */}
          <section className="space-y-3 sm:space-y-4">
            <h2 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold text-white/90">
              10. Limitation of Liability


            </h2>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
            Zulu Club provides the platform "as is" without any warranties of availability, accuracy, or reliability.

We are not responsible for product quality, delivery issues, or disputes between users and vendors.

To the maximum extent permitted by law, Zulu Club shall not be liable for any indirect, incidental, or consequential damages arising from the use of our services.



            </p>
          </section>

          {/* Privacy Policy */}
          <section id="privacy-policy" className="space-y-3 sm:space-y-4">
            <h2 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold text-white/90">
              11. Privacy Policy



            </h2>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
            Your use of Zulu Club is also governed by our Privacy Policy, which outlines how we collect, store, and process your data.




            </p>
          </section>

          <section className="space-y-3 sm:space-y-4">
            <h2 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold text-white/90">
              12. Modifications to Terms




            </h2>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
            We reserve the right to modify or update these Terms at any time. Continued use of our platform after changes constitutes acceptance of the revised Terms.





            </p>
          </section>

          {/* Contact Information */}
          <section className="space-y-3 sm:space-y-4">
            <h2 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold text-white/90">
              13. Contact Information
            </h2>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
              If you have any questions or concerns regarding these Terms & Conditions, please contact us at:
              <br />
              <a 
                href="mailto:hi@zulu.club" 
                className="text-white hover:text-gray-300 underline decoration-dotted"
              >
                hi@zulu.club
              </a>
            </p>
            <p className="text-sm sm:text-base md:text-lg xl:text-xl text-gray-300 leading-relaxed">
              By using Zulu Club, you agree to be bound by these Terms and Conditions. Thank you for shopping with us!
            </p>
          </section>
        </div>
      </div>

      {/* Key Terms & Conditions Section */}
      <div className="mt-16 max-w-3xl mx-auto translate-y-[-10%] xl:translate-y-[-40%] 2xl:translate-y-[-40%]">
        <div className="bg-gray-50 rounded-2xl p-8 border border-gray-200">
          <h3 className="text-xl font-semibold text-black mb-6 text-center">
            Zulu Benefits
          </h3>
          <div className="space-y-4 flex flex-col items-center">
            {/* ... rest of the benefits content ... */}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TermsAndConditions;
