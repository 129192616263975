import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProductById } from '../services/api';

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadProduct = async () => {
      try {
        setLoading(true);
        const data = await fetchProductById(id);
        setProduct(data);
      } catch (err) {
        console.error('Error loading product:', err);
        setError('Failed to load product details');
      } finally {
        setLoading(false);
      }
    };

    loadProduct();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-white flex flex-col items-center justify-center">
        <p className="text-red-500 mb-4">{error}</p>
        <button 
          onClick={() => window.history.back()}
          className="px-4 py-2 bg-black text-white rounded-lg hover:bg-black/80"
        >
          Go Back
        </button>
      </div>
    );
  }

  if (!product) return null;

  return (
    <div className="min-h-screen bg-white py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Product Images */}
          <div className="space-y-4">
            <div className="aspect-w-3 aspect-h-4 rounded-lg overflow-hidden">
              <img
                src={product.image_url || product.image}
                alt={product.name}
                className="w-full h-full object-cover"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'https://via.placeholder.com/600x800?text=Product+Image';
                }}
              />
            </div>
          </div>

          {/* Product Info */}
          <div className="space-y-6">
            {product.brand && (
              <p className="text-gray-500 text-sm font-medium tracking-wider uppercase">
                {product.brand}
              </p>
            )}
            
            <h1 className="text-3xl font-bold text-gray-900">
              {product.name}
            </h1>

            <div className="space-y-2">
              {product.special_price ? (
                <>
                  <p className="text-2xl font-bold text-gray-900">
                    ₹{parseInt(product.special_price).toLocaleString()}
                    <span className="ml-2 text-lg text-gray-500 line-through">
                      ₹{parseInt(product.price).toLocaleString()}
                    </span>
                  </p>
                  <p className="text-green-600">
                    {Math.round(((product.price - product.special_price) / product.price) * 100)}% off
                  </p>
                </>
              ) : (
                <p className="text-2xl font-bold text-gray-900">
                  ₹{parseInt(product.price).toLocaleString()}
                </p>
              )}
            </div>

            {product.description && (
              <div className="prose prose-sm text-gray-500">
                <h3 className="text-lg font-medium text-gray-900">Description</h3>
                <p>{product.description}</p>
              </div>
            )}

            {/* Add to Cart Button */}
            <button className="w-full bg-black text-white py-3 px-6 rounded-lg
                           hover:bg-black/80 transition-colors duration-200">
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail; 