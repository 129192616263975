import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ConsumerPage from './pages/consumer_page';
import AskZuluPage from './pages/AskZuluPage';
import BrandsPage from './pages/BrandsPage';
import ContactSalesPage from './pages/ContactSalesPage';
import AboutUsPage from './pages/AboutUsPage';
import ConciergePage from './pages/ConciergePage';
import TermsAndConditions from './pages/Terms&Conditions';
import ShopNowPage from './pages/ShopNowPage';
import ProductDetail from './components/ProductDetail';
// ... other imports

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ConsumerPage />} />
        <Route path="/ask-zulu" element={<AskZuluPage />} />
        <Route path="/brands" element={<BrandsPage />} />
        <Route path="/contact-sales" element={<ContactSalesPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/concierge" element={<ConciergePage />} />
        <Route path="/tnc" element={<TermsAndConditions />} />
        <Route path="/shop" element={<ShopNowPage />} />
        <Route path="/product/:id" element={<ProductDetail />} />
      </Routes>
    </Router>
  );
}

export default App; 