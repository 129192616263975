import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styles from './BrandsPage.module.css';
import Navbar from '../components/Navbar';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import Footer from '../components/Footer';
import customerIcon from '../assets/customer.png';
import brandIcon from '../assets/brand.png';
import nothingIcon from '../assets/nothing.png';
import zuluLogo from '../assets/Zulu Logo.jpg';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import ReactGA from 'react-ga4';

const BrandsPage = () => {
  const [showThankYou, setShowThankYou] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null);
  const getStartedRef = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Initialize GA4
    ReactGA.initialize('G-WQPMLR391T');
    
    // Send pageview
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });

    console.log('isSubmitted state:', isSubmitted);
  }, [isSubmitted]);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const getISTTimestamp = () => {
    const now = new Date();
    // Add 5 hours and 30 minutes for IST
    now.setHours(now.getHours() + 5);
    now.setMinutes(now.getMinutes() + 30);
    return now.toISOString();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submit clicked');
    
    // Validate phone number
    // const phoneRegex = /^\d{10}$/;
    // if (!phoneRegex.test(phoneNumber.trim())) {
    //   alert('Please enter a valid 10-digit phone number');
    //   return;
    // }

    // setIsSubmitting(true);

    try {
      const timestamp = getISTTimestamp();
      const jsonData = {
        // phoneNumber: phoneNumber.trim(),
        timestamp: timestamp,
        type: 'Business'
      };

      const response = await fetch('https://hooks.zapier.com/hooks/catch/21065465/28s8lvn/', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData)
      });

      console.log('Request sent successfully');
      console.log('Submitted Data:', jsonData);
      
      setIsSubmitted(true);
      // setPhoneNumber('');
      
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error submitting your number. Please try again.');
    } finally {
      // setIsSubmitting(false);
    }
  };

  const scrollToGetStarted = () => {
    getStartedRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const faqs = [
    {
      question: "How long does it take to go live on Zulu?",
      answer: "Your store can go live within 100 minutes after providing the necessary details and completing the verification process."
    },
    {
      question: "What documents do I need to register my store?",
      answer: "You'll need basic business documentation including store details, address proof, and inventory information."
    },
    {
      question: "How does the payment process work?",
      answer: "We process secure payments and transfer them directly to your registered bank account."
    },
    {
      question: "Do I need to maintain separate inventory for Zulu?",
      answer: "No, you can manage your existing inventory through our easy-to-use business app."
    },
    {
      question: "What are the commission rates?",
      answer: "Our commission structure is transparent and competitive. Contact our team for detailed information."
    },
    {
      question: "How do I manage orders through Zulu?",
      answer: "You can manage all orders through our dedicated business app available on both iOS and Android."
    },
    {
      question: "What kind of support does Zulu provide?",
      answer: "We provide 24/7 support for all our partner stores through phone, email, and chat."
    },
    {
      question: "Can I list all my products on Zulu?",
      answer: "Yes, you can list your entire inventory on Zulu, making it easier for customers to browse your complete collection."
    }
  ];

  const checklistItems = [
    "Register your store",
    "Set up your profile",
    "Start receiving orders",
    "Grow your business"
  ];

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>Partner with Zulu: Join Zulu And Boost Your Brand's Growth</title>
      </Helmet>
      
      <Navbar />
      
      {/* Partner Section with Background Image */}
      <div className={`${styles.heroSection} text-white min-h-[60vh] md:min-h-[80vh] 2xl:min-h-[90vh] relative flex items-center justify-center bg-black`}>
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 2xl:max-w-[1400px] relative z-10">
          <div className="flex flex-col items-center justify-center mt-20 md:mt-32">
            <h1 className="font-bold text-3xl sm:text-3xl md:text-[50px] 2xl:text-[60px] mt-0 mb-4 md:mb-6 text-center">
              Partner with Zulu
              <div className="md:mt-10 text-2xl mt-2 sm:text-2xl md:text-[45px] 2xl:text-[55px]">
                & grow your brand
              </div>
            </h1>
            <p className="text-base sm:text-lg md:text-1xl 2xl:text-2xl text-gray-300 mb-6 md:mb-8 max-w-2xl 2xl:max-w-3xl mx-auto text-center font-light leading-relaxed mt-1 md:mt-6">
              Serve premium shoppers of Gurgaon in 100 mins
              <br className="mb-1" />
              <span className="mt-4 md:mt-2 block">
                
              </span>
            </p>
            
            {/* Download Business App Button */}
            <button 
              onClick={() => window.open('https://play.google.com/store/apps/details?id=com.zulu.seller&hl=en_US', '_blank')}
              className="mt-8 md:mt-1 px-8 py-3 2xl:px-10 2xl:py-4 bg-white text-black rounded-lg hover:bg-white/90 transition-all duration-300 font-light 2xl:text-lg"
            >
              Download Business App
            </button>
          </div>
        </div>
      </div>

      {/* Why Partner Section */}
      <div className="py-24 2xl:py-32 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 2xl:max-w-[1400px]">
          {/* Section Header */}
          <div className="text-center space-y-6 mb-16 2xl:mb-20">
            <span className="text-sm 2xl:text-base font-medium text-gray-600 tracking-wider uppercase">
              Partnership Benefits
            </span>
            <h2 className="text-3xl md:text-4xl 2xl:text-5xl font-bold text-black">
              Why should you partner with Zulu?
            </h2>
            <p className="text-lg 2xl:text-xl text-gray-600 font-light max-w-2xl 2xl:max-w-3xl mx-auto">
              Join us to expand your reach and grow your business
            </p>
          </div>

          {/* Features Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 2xl:gap-10 max-w-6xl 2xl:max-w-7xl mx-auto">
            {[
              {
                title: "Zulu asks for nothing",
                description: "No dedicated inventory or manpower",
                icon: nothingIcon
              },
              {
                title: "Helps build your brand",
                description: "Full visibility, personal touch, premium upsells",
                icon: brandIcon
              },
              {
                title: "Attract new customers",
                description: "Popups in premium societies close to your outlet",
                icon: customerIcon
              }
            ].map((feature, index) => (
              <div 
                key={index}
                className="relative rounded-2xl bg-gray-50 border border-gray-200 
                         backdrop-blur-sm p-8 2xl:p-10"
              >
                <div className="space-y-6 2xl:space-y-8">
                  <div className="w-16 h-16 2xl:w-20 2xl:h-20 rounded-2xl 
                              bg-gradient-to-br from-white/30 via-white/40 to-white/10 
                              p-3 border border-white/30
                              shadow-[0_0_20px_rgba(255,255,255,0.15)]">
                    <img 
                      src={feature.icon}
                      alt={feature.title}
                      className="w-full h-full object-contain brightness-[2.1] filter"
                    />
                  </div>

                  <div className="space-y-3 2xl:space-y-4">
                    <h3 className="text-xl 2xl:text-2xl font-semibold text-black">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600 2xl:text-lg leading-relaxed">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Membership Plans Section */}
      <div className="">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 2xl:px-16">
          {/* Section Header */}
          <div className="text-center space-y-4 sm:space-y-6 mb-12 sm:mb-16">
            {/* <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl
                           font-bold text-black">
              Partnership Plans
            </h2> */}
          </div>

          {/* Plans Grid - Desktop */}
          <div className="hidden md:grid md:grid-cols-3 gap-6 lg:gap-8 max-w-7xl mx-auto">
            {/* Plan Card Template - repeated for each plan */}
            {[
              // {
              //   title: "Inspire",
              //   features: [
              //     "Standalone Store",
              //     "In-Store Salesperson",
              //     "3 Featured Videos & 2 Banner",
              //     "Access to All Zulu Events"
              //   ]
              // },
              // {
              //   title: "Signature",
              //   features: [
              //     "Partner Mall Store",
              //     "In-Store Salesperson",
              //     "3 Featured Videos & 1 Banner",
              //     "3 Zulu Events per Year"
              //   ]
              // },
              // {
              //   title: "Icon",
              //   features: [
              //     "Partner Mall Store",
              //     "Zulu Shopper",
              //     "2 Featured Videos",
              //     "1 Zulu Event per Year"
              //   ]
              // }
            ].map((plan, index) => (
              <div key={index} 
                   className="bg-gray-50 rounded-2xl p-6 lg:p-8 border border-gray-200 
                            hover:bg-gray-100 transition-all duration-300
                            hover:scale-[1.02] hover:shadow-xl">
                <div className="text-center mb-8">
                  <h3 className="text-3xl font-bold text-black mb-4">{plan.title}</h3>
                  <div className="h-[2px] w-24 bg-gray-200 mx-auto"></div>
                </div>
                <div className="space-y-6">
                  {plan.features.map((feature, featureIndex) => (
                    <div key={featureIndex} className="flex items-center gap-4">
                      <div className="text-green-400">
                        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p className="text-gray-600 text-lg">
                        {feature}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* Plans Slider - Mobile */}
          {/* <div className="md:hidden">
            <Swiper
              // modules={[Pagination]}
              // spaceBetween={20}
              // slidesPerView={1}
              // pagination={{ clickable: true }}
              // className="mySwiper"
            >
              {[
                // {
                //   title: "Inspire",
                //   features: [
                //     "Standalone",
                //     "In-Store",
                //     "3 Videos & 2 Banner",
                //     "Access to All Events"
                //   ]
                // },
                // {
                //   title: "Signature",
                //   features: [
                //     "Partner Mall",
                //     "In-Store",
                //     "3 Videos & 1 Banner",
                //     "3 Events per Year"
                //   ]
                // },
                // {
                //   title: "Icon",
                //   features: [
                //     "Partner Mall",
                //     "Zulu Shopper",
                //     "2 Videos",
                //     "1 Event per Year"
                //   ]
                // }
              ].map((plan, index) => (
                <SwiperSlide key={index}>
                  <div className="bg-gray-50 rounded-2xl p-6 lg:p-8 border border-gray-200 
                                hover:bg-gray-100 transition-all duration-300
                                hover:scale-[1.02] hover:shadow-xl min-h-[400px]">
                    <div className="text-center mb-8">
                      <h3 className="text-3xl font-bold text-black mb-4">{plan.title}</h3>
                      <div className="h-[2px] w-24 bg-gray-200 mx-auto"></div>
                    </div>
                    <div className="space-y-6">
                      {plan.features.map((feature, featureIndex) => (
                        <div key={featureIndex} className="flex items-center gap-4">
                          <div className="text-green-400">
                            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2} 
                                    d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="text-gray-600 text-lg">
                            {feature}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div> */}

          {/* Key Terms & Conditions Section */}
          <div className="mt-16 max-w-3xl mx-auto translate-y-[-10%] xl:translate-y-[-40%] 2xl:translate-y-[-40%]">
            <div className="bg-gray-50 rounded-2xl p-8 border border-gray-200">
              <h3 className="text-xl font-semibold text-black mb-6 text-center">
                Zulu Benefits
              </h3>
              <div className="space-y-4 flex flex-col items-center">
                <div className="flex items-center justify-center gap-3">
                  <div className="text-gray-600">
                    •
                  </div>
                  <p className="text-gray-600 text-sm xl:text-base 2xl:text-lg text-center">
                  We handle all delivery &  return logistics of even your existing orders

                  </p>
                </div>
                <div className="flex items-center justify-center gap-3">
                  <div className="text-gray-600">
                    •
                  </div>
                  <p className="text-gray-600 text-sm xl:text-base 2xl:text-lg text-center">
                  Real-time inventory updation to ensure no orders are missed
                  </p>
                </div>
                <div className="flex items-center justify-center gap-3">
                  <div className="text-gray-600">
                    •
                  </div>
                  <p className="text-gray-600 text-sm xl:text-base 2xl:text-lg text-center">
                  Grow with our online & offline marketing campaigns
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Get in Touch Section - Commented out for now
          <div className="mt-16 text-center">
            <div className="space-y-6">
              <p className="text-gray-300 text-lg">
                Want to know more about our partnership plans?
              </p>
              <button 
                onClick={() => navigate('/contact-sales')}
                className="px-8 py-4 bg-white text-black rounded-xl 
                         hover:bg-white/90 transition-all duration-300 
                         text-lg font-medium
                         transform hover:scale-105"
              >
                Get in Touch
              </button>
            </div>
          </div>
          */}
        </div>
      </div>

      {/* Get Started Section - Moved after Partnership Plans */}
      <div ref={getStartedRef} className="bg-white text-black translate-y-[10%] xl:translate-y-[0%] 2xl:translate-y-[5%]">
        <div className="container mx-auto px-4 py-16">
          <div className="flex flex-col md:flex-row justify-center items-center gap-6 max-w-6xl mx-auto">
            {/* Left Content */}
            <div className="w-full md:w-1/2 text-center md:text-left">
              <h2 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-black via- to-black bg-clip-text text-transparent">
                Get in touch 
              </h2>
              <p className="text-xl text-gray-600 mb-8">
                We will take your outlet live in 100 mins
              </p>
              <div className="flex justify-center md:justify-start">
                <button 
                  onClick={() => navigate('/contact-sales')}
                  className="px-8 py-4 bg-black text-white rounded-xl 
                           hover:bg-black/90 transition-all duration-300 
                           text-lg font-medium transform hover:scale-105
                           shadow-lg hover:shadow-xl"
                >
                  Contact Us
                </button>
              </div>
            </div>

            {/* Right Content - Checklist Box */}
            <div className="w-full md:w-5/12 bg-gray-50 rounded-2xl p-8 border border-gray-200
                          backdrop-blur-sm hover:bg-gray-100 transition-all duration-300">
              <h3 className="text-2xl font-semibold mb-6">Get Started</h3>
              <p className="text-gray-600 mb-8">
                Please be ready with the following for a smooth registration
              </p>
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="text-green-400 mt-1">
                    <CheckCircleIcon className="w-6 h-6" />
                  </div>
                  <span className="text-gray-600 text-lg">
                    Outlet details & address proof
                  </span>
                </div>
                <div className="flex items-start gap-4">
                  <div className="text-green-400 mt-1">
                    <CheckCircleIcon className="w-6 h-6" />
                  </div>
                  <span className="text-gray-600 text-lg">
                    Website and social media links
                  </span>
                </div>
                <div className="flex items-start gap-4">
                  <div className="text-green-400 mt-1">
                    <CheckCircleIcon className="w-6 h-6" />
                  </div>
                  <span className="text-gray-600 text-lg">
                    Stylewise inventory data of the outlet
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* How it Works Section
      <div className="py-16 bg-black">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-white">
            How it works
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {[
              {
                step: "1",
                title: "Take your outlet live",
                description: "Quick and easy registration process"
              },
              {
                step: "2",
                title: "Get new customers",
                description: "Give video tours to get orders"
              },
              {
                step: "3",
                title: "100 minute delivery",
                description: "Express delivery for all your customers"
              },
              {
                step: "4",
                title: "Offline events",
                description: "Showcase your brand in pop-ups near you"
              }
              
               
            ].map((item, index) => (
              <div key={index} className="text-center">
                <div className="w-12 h-12 bg-gray-800 rounded-full text-white flex items-center justify-center mx-auto mb-4">
                  {item.step}
                </div>
                <h3 className="text-lg font-semibold mb-2 text-white">{item.title}</h3>
                <p className="text-gray-300">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {/* FAQs Section with modern styling */}
      <div className="py-20 bg-white translate-y-[0%] xl:translate-y-[0%] 2xl:translate-y-[100px]">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          {/* Section Header */}
          <div className="text-center mb-16 space-y-4">
            <h2 className="text-3xl md:text-4xl font-bold text-black">
              Frequently Asked Questions
            </h2>
            <p className="text-gray-600 text-lg">
              Everything you need to know about partnering with Zulu
            </p>
          </div>

          {/* FAQ Grid */}
          <div className="max-w-3xl 2xl:max-w-4xl mx-auto space-y-4 2xl:space-y-6">
            {faqs.map((faq, index) => (
              <Disclosure key={index}>
                {({ open }) => (
                  <div className="group transform transition-all duration-300 hover:scale-[1.01]">
                    <Disclosure.Button 
                      className={`w-full px-6 py-4 2xl:px-8 2xl:py-6 text-left flex justify-between items-center 
                                 rounded-lg bg-gray-50 hover:bg-gray-100 
                                 transition-all duration-200 border border-gray-200
                                 ${open ? 'rounded-b-none border-b-0' : ''}`}
                    >
                      <span className="text-black font-medium pr-8 text-base 2xl:text-lg">
                        {faq.question}
                      </span>
                      <ChevronUpIcon
                        className={`${
                          open ? 'transform rotate-180' : ''
                        } w-5 h-5 2xl:w-6 2xl:h-6 text-gray-600 transition-transform duration-300`}
                      />
                    </Disclosure.Button>

                    <Transition
                      enter="transition duration-200 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-100 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel 
                        className={`px-6 py-4 2xl:px-8 2xl:py-6 bg-gray-50 text-gray-600 leading-relaxed
                                   border border-t-0 border-gray-200 rounded-b-lg
                                   transform transition-all duration-200`}
                      >
                        <div className="prose prose-invert max-w-none">
                          <p className="text-gray-600 text-base 2xl:text-lg">
                            {faq.answer}
                          </p>
                        </div>
                      </Disclosure.Panel>
                    </Transition>
                  </div>
                )}
              </Disclosure>
            ))}
          </div>

          {/* Optional: Call to Action at bottom of FAQs */}
          {/* <div className="mt-16 text-center space-y-6">
            <p className="text-gray-600 text-lg">
              Still have questions?
            </p>
            <button 
              onClick={() => window.open('https://play.google.com/store/apps/details?id=com.zulu.seller&hl=en_US', '_blank')}
              className="group relative px-8 py-3 bg-black text-white rounded-lg 
                       transform transition-all duration-300 hover:scale-[1.02]
                       hover:shadow-[0_0_20px_rgba(255,255,255,0.15)]"
            >
              <span className="relative z-10 font-medium">
                Download Business App
              </span>
              <div className="absolute inset-0 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-300 
                            opacity-0 group-hover:opacity-100 rounded-lg transition-opacity duration-300" />
            </button>
          </div> */}
        </div>
      </div>

      {/* Add Footer component here */}
      <Footer />

      {/* Add custom styles for Swiper pagination */}
      <style jsx>{`
        .swiper-pagination-bullet {
          background: black !important;
          opacity: 0.5;
        }
        .swiper-pagination-bullet-active {
          opacity: 1;
        }
        .swiper {
          padding-bottom: 40px;
        }
      `}</style>
    </div>
  );
};

export default BrandsPage; 