import React, { useState, useRef, useEffect } from 'react';
import Navbar from '../components/Navbar-AskZulu';
import Footer from '../components/Footer';
import ConversationalAgent from '../agents/ConversationalAgent';
import { MessageCircle, Send, ExternalLink } from 'lucide-react';
import ReactGA from 'react-ga4';
import DownloadAppPopup from '../components/DownloadAppPopup';

const ProductCard = ({ product, explanation }) => {
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  //console.log('Full product data:', JSON.stringify(product, null, 2));

  // Handle both data structures (filtered products and recommendations)
  const {
    id,
    basic_info,
    // For recommendations response
    name: directName,
    brand: directBrand,
    price: directPrice,
    image_url: directImageUrl,
    product_url: directProductUrl,
    mall_name: directMall,
    // Additional price fields that might be at root level
    discounted_price: rootDiscountedPrice,
    mrp: rootMrp
  } = product || {};

  // If basic_info exists, use the filtered products structure
  if (basic_info) {
    const {
      brand = { detected: 'Unknown Brand' },
      product_details = {},
      price = {}
    } = basic_info;

    const {
      name = 'Unknown Product',
      image_url = '',
      link: product_url = '',
      mall_name: mall = ''
    } = product_details;

    const {
      discounted_price: currentPrice = '',
      mrp: originalPrice = ''
    } = price;

    const handleProductClick = (e) => {
      e.preventDefault();
      setShowDownloadPopup(true);
    };

    return (
      <>
        <div 
          onClick={handleProductClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="relative bg-white rounded-lg overflow-hidden hover:bg-gray-50 
                   transition-all duration-200 cursor-pointer border border-gray-200"
        >
          {image_url && (
            <div className="relative h-52">
              <img 
                src={image_url}
                alt={name}
                className="w-full h-full object-cover"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/placeholder-image.png';
                }}
              />
            </div>
          )}
          <div className="p-2">
            <p className="text-[12px] text-gray-600">{brand.detected}</p>
            <h3 className="text-sm font-medium text-gray-900 truncate mt-0.5">{name}</h3>
            {mall && <p className="text-[12px] text-gray-500 mt-0.5">{mall}</p>}
            <div className="mt-1 flex justify-between items-center">
              <div className="flex items-center gap-1">
                <p className="text-sm font-medium text-gray-900">
                  ₹{currentPrice || originalPrice || 'N/A'}
                </p>
                {originalPrice && currentPrice && originalPrice !== currentPrice && (
                  <p className="text-[11px] text-gray-400 line-through">₹{originalPrice}</p>
                )}
              </div>
              {product_url && (
                <span className="text-blue-500 hover:text-blue-600">
                  <ExternalLink size={14} />
                </span>
              )}
            </div>
          </div>

          {/* Hover Explanation Popup */}
          {isHovered && explanation && (
            <div className="absolute inset-x-0 bottom-0 bg-white/95 backdrop-blur-sm
                          transform transition-all duration-300 ease-out
                          flex items-center justify-center p-4
                          border-t border-gray-200">
              <p className="text-sm text-gray-700 leading-relaxed text-center">
                {explanation}
              </p>
            </div>
          )}
        </div>
        <DownloadAppPopup 
          isOpen={showDownloadPopup} 
          onClose={() => setShowDownloadPopup(false)} 
        />
      </>
    );
  }

  // For recommendations response structure
  const finalPrice = {
    discounted_price: directPrice?.discounted_price || rootDiscountedPrice || '',
    mrp: directPrice?.mrp || rootMrp || ''
  };

  // Get image URL from product.basic_info structure for recommendations
  const imageUrl = product?.basic_info?.product_details?.image_url || directImageUrl || '';
  console.log('Resolved image URL:', imageUrl);

  const handleProductClick = (e) => {
    e.preventDefault();
    setShowDownloadPopup(true);
  };

  return (
    <>
      <div 
        onClick={handleProductClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="relative bg-white rounded-lg overflow-hidden hover:bg-gray-50 
                 transition-all duration-200 cursor-pointer border border-gray-200"
      >
        {imageUrl && (
          <div className="relative h-52">
            <img 
              src={imageUrl}
              alt={directName || 'Product image'}
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/placeholder-image.png';
              }}
            />
          </div>
        )}
        <div className="p-2">
          <p className="text-[12px] text-gray-600">{directBrand || product?.basic_info?.brand?.detected || 'Unknown Brand'}</p>
          <h3 className="text-sm font-medium text-gray-900 truncate mt-0.5">
            {directName || product?.basic_info?.product_details?.name || 'Unknown Product'}
          </h3>
          {directMall && <p className="text-[12px] text-gray-500 mt-0.5">{directMall}</p>}
          <div className="mt-1 flex justify-between items-center">
            <div className="flex items-center gap-1">
              <p className="text-sm font-medium text-gray-900">
                ₹{finalPrice.discounted_price || finalPrice.mrp || 'N/A'}
              </p>
              {finalPrice.mrp && finalPrice.discounted_price && 
               finalPrice.mrp !== finalPrice.discounted_price && (
                <p className="text-[11px] text-gray-400 line-through">₹{finalPrice.mrp}</p>
              )}
            </div>
            {(directProductUrl || product?.basic_info?.product_details?.link) && (
              <span className="text-blue-500 hover:text-blue-600">
                <ExternalLink size={14} />
              </span>
            )}
          </div>
        </div>

        {/* Hover Explanation Popup */}
        {isHovered && explanation && (
          <div className="absolute inset-x-0 bottom-0 bg-white/95 backdrop-blur-sm
                        transform transition-all duration-300 ease-out
                        flex items-center justify-center p-4
                        border-t border-gray-200">
            <p className="text-sm text-gray-700 leading-relaxed text-center">
              {explanation}
            </p>
          </div>
        )}
      </div>
      <DownloadAppPopup 
        isOpen={showDownloadPopup} 
        onClose={() => setShowDownloadPopup(false)} 
      />
    </>
  );
};

const RecommendationsSection = ({ recommendedProducts, explanations, styleSuggestions }) => {
  // Clean style suggestions by removing product IDs and any references
  const cleanStyleSuggestions = styleSuggestions?.map(suggestion => {
    return suggestion
      // Remove product IDs in various formats (#123, [123], (123))
      .replace(/#\d+|\[\d+\]|\(\d+\)/g, '')
      // Remove any remaining brackets and parentheses
      .replace(/[\[\]\(\)]/g, '')
      // Remove any double spaces created
      .replace(/\s+/g, ' ')
      // Trim whitespace
      .trim();
  }).filter(suggestion => 
    // Remove empty suggestions and those that are just spaces
    suggestion.length > 0 && suggestion !== ' '
  );

  console.log('Raw recommended products:', recommendedProducts);

  if (!recommendedProducts || recommendedProducts.length === 0) {
    console.log('No recommendations to display');
    return null;
  }

  // Map the raw product data to the expected structure
  const formattedProducts = recommendedProducts.map(product => {
    console.log('Raw product data:', product); // Debug log

    // Handle both possible data structures
    const zulu_data = product.zulu_data || product;
    
    return {
      id: product.id,
      basic_info: {
        product_details: {
          name: zulu_data.name || 'Unknown Product',
          image_url: zulu_data.image_url || '',
          link: zulu_data.product_url || '',
          mall_name: zulu_data.mall || zulu_data.mall_name || '',
          product_url: zulu_data.product_url || ''
        },
        brand: {
          detected: zulu_data.brand || 'Unknown Brand'
        },
        price: {
          mrp: zulu_data.price?.mrp || product.price?.mrp || '',
          discounted_price: zulu_data.price?.discounted_price || product.price?.discounted_price || ''
        },
        classification: {
          category: {
            main: zulu_data.category || '',
            sub: zulu_data.sub_category || ''
          }
        }
      }
    };
  });

  console.log('Formatted products:', formattedProducts);

  return (
    <div className="mt-8 bg-white rounded-lg p-6 border border-gray-200 shadow-sm">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Recommended For You</h2>
        {cleanStyleSuggestions && cleanStyleSuggestions.length > 0 && (
          <div className="text-base text-gray-700 mb-4">
            <ul className="list-disc pl-5 space-y-2">
              {cleanStyleSuggestions.map((suggestion, index) => (
                <li key={index} className="text-base leading-relaxed font-medium">{suggestion}</li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {formattedProducts.map((product, index) => (
          <ProductCard 
            key={`${product.id}-${index}`}
            product={product} 
            explanation={explanations[product.id]}
          />
        ))}
      </div>
    </div>
  );
};

const ProductsDisplay = ({ title, products, explanation = null }) => {
  if (!products || products.length === 0) return null;

  return (
    <div className="mt-8">
      {explanation && (
        <div className="bg-white rounded-lg p-6 mb-6 border border-gray-200 shadow-sm">
          <p className="text-base text-gray-700 leading-relaxed font-medium">{explanation}</p>
        </div>
      )}
      <h2 className="text-2xl font-bold text-gray-900 mb-4">{title}</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
        {products.map((product, index) => (
          <ProductCard 
            key={`filtered-${index}`} 
            product={product} 
          />
        ))}
      </div>
    </div>
  );
};

const AskZuluPage = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [recommendationExplanations, setRecommendationExplanations] = useState({});
  const [styleSuggestions, setStyleSuggestions] = useState('');
  const agent = useRef(new ConversationalAgent());

  useEffect(() => {
    // Initialize GA4
    ReactGA.initialize('G-WQPMLR391T');
    
    // Send pageview
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });

    // Add initial greeting
    if (messages.length === 0) {
      setMessages([
        {
          text: "Hi! Welcome to Zulu Club. What can I assist you with today?",
          sender: 'ai'
        }
      ]);
    }
  }, [messages.length]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim() || isLoading) return;

    const userMessage = {
      text: inputValue.trim(),
      sender: 'user'
    };

    try {
      setIsLoading(true);
      setMessages(prev => [...prev, userMessage]);
      setInputValue('');

      const response = await agent.current.handleQuery(
        userMessage.text,
        messages
      );

      console.log('Response from agent:', response);

      // Clear previous recommendations
      setRecommendedProducts([]);
      setRecommendationExplanations({});
      setStyleSuggestions([]);

      // Check for recommendations
      if (response.recommendations) {
        console.log('Processing recommendations:', response.recommendations);
        
        // Update recommendations state
        if (response.recommendations.products) {
          console.log('Setting recommended products:', response.recommendations.products);
          setRecommendedProducts(response.recommendations.products);
        }
        
        if (response.recommendations.explanations) {
          console.log('Setting explanations:', response.recommendations.explanations);
          setRecommendationExplanations(response.recommendations.explanations);
        }
        
        if (response.recommendations.style_suggestions) {
          console.log('Setting style suggestions:', response.recommendations.style_suggestions);
          setStyleSuggestions(response.recommendations.style_suggestions);
        }
      }

      // Update filtered products if available
      if (response.filteredProducts) {
        console.log('Setting filtered products:', response.filteredProducts);
        // Limit to first 8 products (or any number you prefer)
        const limitedProducts = response.filteredProducts.slice(0,20);
        setFilteredProducts(limitedProducts);
      }

      // Add AI response to messages
      const aiMessage = {
        text: response.reply,
        sender: 'ai',
        context: response.context
      };

      setMessages(prev => [...prev, aiMessage]);
    } catch (error) {
      console.error('Chat error:', error);
      setMessages(prev => [...prev, {
        text: "I'm sorry, I encountered an error. Please try again.",
        sender: 'ai'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white min-h-screen">
      <Navbar forceDark={true} />
      <div className="pt-16 sm:pt-24 px-2 sm:px-4 max-w-[1920px] mx-auto">
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-8">
          
          {/* Chat Container - Fixed height with scrollable messages */}
          <div className="w-full translate-y-[5%] md:translate-y-0 lg:w-[40%] lg:sticky lg:top-24 lg:self-start">
            <div className="bg-white rounded-lg overflow-hidden border-[2px] border-gray-300 shadow-lg mx-auto">
              {/* Chat Header */}
              <div className="bg-gray-50 p-3 sm:p-4 border-b-[2px] border-gray-300">
                <div className="flex items-center gap-2">
                  <MessageCircle className="w-5 h-5 sm:w-6 sm:h-6 text-gray-600" />
                  <h1 className="text-base sm:text-xl font-bold text-gray-900">Ask Zulu</h1>
                </div>
              </div>

              {/* Chat Content - Fixed height container */}
              <div className="h-[70vh] lg:h-[75vh] flex flex-col"> {/* Fixed height */}
                {/* Messages Area - Scrollable */}
                <div className="flex-1 overflow-y-auto p-3 sm:p-5 space-y-2 sm:space-y-4">
                  {messages.map((message, index) => (
                    <div
                      key={index}
                      className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                    >
                      <div
                        className={`max-w-[85%] sm:max-w-[80%] rounded-lg px-4 py-2.5 shadow-sm ${
                          message.sender === 'user'
                            ? 'bg-gray-600 text-white'
                            : 'bg-gray-100 text-gray-900 border-1 border-gray-300'
                        }`}
                      >
                        <p className="text-[15px] leading-relaxed">{message.text}</p>
                      </div>
                    </div>
                  ))}
                  {isLoading && (
                    <div className="flex justify-start">
                      <div className="bg-gray-100 text-gray-900 rounded-lg px-4 py-2.5 border-1 border-gray-300 shadow-sm">
                        <p className="text-[15px]">Thinking...</p>
                      </div>
                    </div>
                  )}
                </div>

                {/* Input Form - Fixed at bottom */}
                <div className="border-t border-gray-200 p-3 sm:p-4 bg-white">
                  <form onSubmit={handleSubmit}>
                    <div className="flex gap-2 sm:gap-3">
                      <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Ask about products..."
                        className="flex-1 bg-white rounded-lg px-3 sm:px-4 py-2.5 sm:py-3 
                                 text-[14px] sm:text-[15px] text-gray-900 placeholder:text-gray-400
                                 border-[2px] sm:border-[3px] border-gray-300 hover:border-gray-400
                                 focus:outline-none focus:border-gray-500 focus:ring-2 focus:ring-gray-500/20
                                 transition-all duration-300 shadow-sm"
                        disabled={isLoading}
                      />
                      <button
                        type="submit"
                        disabled={isLoading || !inputValue.trim()}
                        className="bg-gray-600 hover:bg-gray-700 text-white 
                                 px-3 sm:px-4 py-2.5 sm:py-3 rounded-lg shadow-sm
                                 transition-all duration-300
                                 disabled:opacity-50 disabled:cursor-not-allowed
                                 border-2 border-gray-700"
                      >
                        <Send size={20} className="sm:w-6 sm:h-6" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* Products Display Section */}
          <div className="flex-1 mt-4 lg:-mt-[2%] lg:w-[60%]">
            {/* Recommendations Section */}
            {recommendedProducts && recommendedProducts.length > 0 && (
              <RecommendationsSection 
                recommendedProducts={recommendedProducts}
                explanations={recommendationExplanations}
                styleSuggestions={styleSuggestions}
              />
            )}

            {/* Show Filtered Products Section */}
            {filteredProducts && filteredProducts.length > 0 && (
              <div className="mt-8">
                <ProductsDisplay 
                  title="Similar Products"
                  products={filteredProducts}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default AskZuluPage; 