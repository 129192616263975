import React, { useState, useEffect } from 'react';

const CategoryMenu = ({ onCategoryChange }) => {
  const [activeCategory, setActiveCategory] = useState('MEN');
  const [isScrolled, setIsScrolled] = useState(false);

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Notify parent component when category changes
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    onCategoryChange(category);
  };

  return (
    <>
      {/* Desktop Category Menu */}
      <nav className={`hidden md:block fixed top-24 sm:top-30 left-0 right-0 z-40 bg-black/80 backdrop-blur-xl
                    transition-all duration-300 transform
                    ${isScrolled ? 'opacity-0 -translate-y-full pointer-events-none' : 'opacity-100'}`}>
        <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center space-x-12 py-4">
            <button
              onClick={() => handleCategoryClick('ALL')}
              className={`text-base font-semibold px-8 py-1.5 rounded-lg 
                       transition-all duration-300 border
                       ${activeCategory === 'ALL' 
                         ? 'bg-white/20 text-white border-white/30 shadow-lg shadow-black/20' 
                         : 'text-white/80 hover:text-white border-white/10 hover:bg-white/10 hover:border-white/20'}`}
            >
              ALL
            </button>
            <button
              onClick={() => handleCategoryClick('MEN')}
              className={`text-base font-semibold px-8 py-1.5 rounded-lg 
                       transition-all duration-300 border
                       ${activeCategory === 'MEN' 
                         ? 'bg-white/20 text-white border-white/30 shadow-lg shadow-black/20' 
                         : 'text-white/80 hover:text-white border-white/10 hover:bg-white/10 hover:border-white/20'}`}
            >
              MEN
            </button>
            <button
              onClick={() => handleCategoryClick('WOMEN')}
              className={`text-base font-semibold px-8 py-1.5 rounded-lg 
                       transition-all duration-300 border
                       ${activeCategory === 'WOMEN' 
                         ? 'bg-white/20 text-white border-white/30 shadow-lg shadow-black/20' 
                         : 'text-white/80 hover:text-white border-white/10 hover:bg-white/10 hover:border-white/20'}`}
            >
              WOMEN
            </button>
            <button
              onClick={() => handleCategoryClick('KIDS')}
              className={`text-base font-semibold px-8 py-1.5 rounded-lg 
                       transition-all duration-300 border
                       ${activeCategory === 'KIDS' 
                         ? 'bg-white/20 text-white border-white/30 shadow-lg shadow-black/20' 
                         : 'text-white/80 hover:text-white border-white/10 hover:bg-white/10 hover:border-white/20'}`}
            >
              KIDS
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile Category Menu */}
      <nav className={`md:hidden fixed bottom-0 left-0 right-0 z-40 bg-black/95 backdrop-blur-xl
                    transition-all duration-300 transform border-t border-white/10
                    `}>
        <div className="px-4 py-3">
          <div className="flex justify-between space-x-2">
            <button
              onClick={() => handleCategoryClick('MEN')}
              className={`flex-1 text-sm font-medium py-2 rounded-full 
                       transition-all duration-300 border
                       ${activeCategory === 'MEN' 
                         ? 'bg-white/20 text-white border-white/30' 
                         : 'text-white/70 hover:text-white border-white/10 hover:bg-white/10'}`}
            >
              Men
            </button>
            <button
              onClick={() => handleCategoryClick('WOMEN')}
              className={`flex-1 text-sm font-medium py-2 rounded-full 
                       transition-all duration-300 border
                        ${activeCategory === 'WOMEN' 
                         ? 'bg-white/20 text-white border-white/30' 
                         : 'text-white/70 hover:text-white border-white/10 hover:bg-white/10'}`}
            >
                Women
            </button>
            <button
              onClick={() => handleCategoryClick('KIDS')}
              className={`flex-1 text-sm font-medium py-2 rounded-full 
                       transition-all duration-300 border
                       ${activeCategory === 'KIDS' 
                         ? 'bg-white/20 text-white border-white/30' 
                         : 'text-white/70 hover:text-white border-white/10 hover:bg-white/10'}`}
            >
              Kids
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default CategoryMenu;
