import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Zap } from 'lucide-react';
import { createPortal } from 'react-dom';
import zululogo from '../assets/zulu_logo2.png'
import DownloadAppPopup from './DownloadAppPopup';




const Navbar = ({ forceDark }) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);

  // Determine active page based on current path
  const getActivePage = () => {
    const path = location.pathname;
    switch (path) {
      case '/':
        return 'HOME';
      case '/ask-zulu':
        return 'ASK ZULU';
      case '/concierge':
        return 'CONCIERGE';
      case '/brands':
        return 'BRANDS';
      case '/shop':
        return 'SHOP';
      default:
        return '';
    }
  };

  const [activePage, setActivePage] = useState(getActivePage());

  // Update active page when location changes
  useEffect(() => {
    setActivePage(getActivePage());
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav className={`fixed left-0 right-0 z-[9999] transition-all duration-300 py-6
                    ${isScrolled 
                      ? 'bg-black/80 backdrop-blur-md' 
                      : 'bg-transparent'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:max-w-[1440px] 2xl:max-w-[1800px]">
        <div className="flex items-center justify-between h-7">
          {/* Logo text and tagline */}
          <div className="flex-shrink-0 flex items-center gap-3">
            <Link to="/" className="flex-shrink-0">
              <span className="text-white text-2xl translate-y-[15%] font-extrabold tracking-wider hover:opacity-80 transition-opacity duration-200
                            xl:text-2xl 2xl:text-2xl font-montserrat">
                ZULU
              </span>
            </Link>
            <span className="text-white text-sm flex items-center gap-1 font-small transition-all duration-300
                          xl:text-sm 2xl:text-sm ">
              Fashion in <Zap size={14} className="text-red-500 fill-red-500 w-3 h-3" /> 100 mins
            </span>
          </div>

          {/* Spacer div to push nav items to right */}
          <div className="flex-grow"></div>

          {/* Navigation Links - Right aligned with consistent spacing */}
          <div className="hidden md:flex items-center translate-y-[5%] space-x-12 xl:space-x-14 2xl:space-x-20">
            <Link
              to="/"
              className={`text-sm font-medium transition-all duration-300
                        xl:text-sm 2xl:text-base 
                        ${activePage === 'HOME'
                          ? 'text-white font-semibold'
                          : 'text-white/70 hover:text-white'
                        }`}
            >
              HOME
            </Link>
            {/* <Link
              to="/concierge"
              className={`text-sm font-medium transition-all duration-300
                        xl:text-sm 2xl:text-base 
                        ${activePage === 'CONCIERGE'
                          ? 'text-white font-semibold'
                          : 'text-white/70 hover:text-white'
                        }`}
            >
              CONCIERGE
            </Link> */}
            <Link
              to="/ask-zulu"
              className={`text-sm font-medium transition-all duration-300
                        xl:text-sm 2xl:text-base 
                        ${activePage === 'ASK ZULU'
                          ? 'text-white font-semibold'
                          : 'text-white/70 hover:text-white'
                        }`}
            >
              ASK ZULU
            </Link>
          
            <Link
              to="/brands"
              className={`text-sm font-medium transition-all duration-300
                        xl:text-sm 2xl:text-base 
                        ${activePage === 'BRANDS'
                          ? 'text-white font-semibold'
                          : 'text-white/70 hover:text-white'
                        }`}
            >
              BRANDS
            </Link>
            
            <Link
              to="/contact-sales"
              className={`text-sm font-medium transition-all duration-300
                        xl:text-sm 2xl:text-base 
                        ${activePage === 'CONTACT'
                          ? 'text-white font-semibold'
                          : 'text-white/70 hover:text-white'
                        }`}
            >
              CONTACT US
            </Link>

            {/* <Link
              to="/shop"
              className="px-4 py-1.5 rounded-full bg-red-500 hover:bg-red-600
                        text-sm font-medium text-white
                        transition-all duration-300
                        ring-2 ring-red-500/20 hover:ring-red-500/40
                        shadow-lg shadow-red-500/20
                        xl:text-sm 2xl:text-base"
            >
              SHOP NOW
            </Link> */}

            {/* Download Icon - with matching spacing */}
            <button
              onClick={() => setShowDownloadPopup(true)}
              className="p-1.5 rounded-full text-white 
                        relative group
                        transition-all duration-300 translate-y-[-5%]"
              aria-label="Download App"
            >
              {/* Highlight Circle */}
              <div className="absolute inset-0 rounded-full 
                              bg-white/0 group-hover:bg-white/10
                              transition-all duration-300
                              ring-2 ring-white/20 group-hover:ring-white/40
                              -m-0.5">
              </div>
              
              {/* Download Icon */}
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="20" 
                height="20" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                className="xl:w-5 xl:h-5 2xl:w-7 2xl:h-7 relative z-10"
              >
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                <polyline points="7 10 12 15 17 10" />
                <line x1="12" y1="15" x2="12" y2="3" />
              </svg>
            </button>
          </div>

          {/* Mobile Menu Button - Only visible on mobile */}
          <button
            onClick={toggleMenu}
            className="md:hidden p-2 rounded-lg text-gray-300 hover:text-white hover:bg-white/5 
                     transition-all duration-200"
          >
            <Menu size={24} />
          </button>
          
        </div>
        
      </div>
      

      {/* Mobile Menu - Shifted right */}
      {isMenuOpen && (
        <div className="md:hidden absolute top-full left-0 right-0 bg-black/90 backdrop-blur-md border-t border-white/5 z-[60]">
          <div className="px-4 py-6 space-y-4 ml-[5%]">
            <Link
              to="/"
              className={`block px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                ${activePage === 'HOME'
                  ? 'text-white'
                  : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              onClick={() => {
                setActivePage('HOME');
                setIsMenuOpen(false);
              }}
            >
              HOME
            </Link>
            
            <Link
              to="/ask-zulu"
              className={`block px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                ${activePage === 'ASK ZULU'
                  ? 'text-white'
                  : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              onClick={() => {
                setActivePage('ASK ZULU');
                setIsMenuOpen(false);
              }}
            >
              ASK ZULU
            </Link>
            <Link
              to="/brands"
              className={`block px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                ${activePage === 'BRANDS'
                  ? 'text-white'
                  : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              onClick={() => {
                setActivePage('BRANDS');
                setIsMenuOpen(false);
              }}
            >
              BRANDS
            </Link>
            
            <Link
              to="/contact-sales"
              className={`block px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                ${activePage === 'CONTACT'
                  ? 'text-white'
                  : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              onClick={() => {
                setActivePage('CONTACT');
                setIsMenuOpen(false);
              }}
            >
              CONTACT US
            </Link>

            {/* <Link
              to="/shop"
              className="block px-4 py-2 rounded-lg text-sm font-medium 
                        bg-red-500 hover:bg-red-600 text-white
                        transition-all duration-200 w-fit"
              onClick={() => {
                setActivePage('SHOP');
                setIsMenuOpen(false);
              }}
            >
              SHOP NOW
            </Link> */}

            {/* Download Icon for Mobile */}
            <button
              onClick={() => {
                setShowDownloadPopup(true);
                setIsMenuOpen(false);
              }}
              className="w-full text-left px-4 py-2 rounded-lg text-sm font-medium 
                        transition-all duration-200 text-gray-300 hover:text-white hover:bg-white/5
                        flex items-center space-x-2"
              aria-label="Download App"
            >
               <span>DOWNLOAD APP</span>
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="18" 
                height="18" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                className="relative"
              >
                {/* <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                <polyline points="7 10 12 15 17 10" />
                <line x1="12" y1="15" x2="12" y2="3" /> */}
              </svg>
             
            </button>
          </div>
        </div>
      )}

      {/* Render DownloadAppPopup through portal */}
      {showDownloadPopup && createPortal(
        <DownloadAppPopup isOpen={showDownloadPopup} onClose={() => setShowDownloadPopup(false)} />,
        document.getElementById('portal-root')
      )}
    </nav>
  );
};

export default Navbar;
