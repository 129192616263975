import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import DownloadAppPopup from './DownloadAppPopup';

const BrandsGrid = () => {
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);

  const brandItems = [
    {
      storeName: "Zara Ambience Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/10001835651.webp",
      brandLogo: "https://zulushop.in/uploads/seller/Zara_Logo1.png",
    },
    {
      storeName: "Uniqlo Ambience Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000183708.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/UNIQLO_Logo.png",
    },
    {
      storeName: "H & M Ambience Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000183604.webp",
      brandLogo: "https://zulushop.in/uploads/seller/H_M_Logo.png",
    },
    {
      storeName: "House of Fett Ardee Mall",
      description: "Women",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000139146.jpg",       
      brandLogo: "https://zulushop.in/uploads/seller/House_of_Fett_Logo.png",
    },
    {
      storeName: "Skechers Airia Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000139511.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/Skechers1.png",
    },
    {
      storeName: "Jack & Jones Airia Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000105421.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/Jack_Jones_logo.png",
    },
    
  ];

  return (
    <>
      <div className="bg-white py-12 sm:py-16 lg:py-24 2xl:py-24 
                    px-4 sm:px-6 lg:px-8 
                    translate-y-[0%] xl:translate-y-[-8%] 2xl:translate-y-[5%]">
        {/* Heading Section */}
        <div className="max-w-7xl mx-auto text-center mb-10 2xl:mb-16">
          <h2 className="text-3xl sm:text-4xl 2xl:text-5xl font-bold text-gray-900 
                       mb-6 sm:mb-8 lg:mb-10 2xl:mb-12">
            OUTLETS NEAR YOU
          </h2>
          <p className="text-lg sm:text-xl 2xl:text-2xl text-gray-600 font-light">
            Discover the best fashion showrooms across NCR
          </p>
        </div>

        {/* Brands Grid */}
        <div className="max-w-7xl 2xl:max-w-[1400px] mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 
                        gap-6 sm:gap-8 lg:gap-10 2xl:gap-12">
            {brandItems.map((item, index) => (
              <div
                key={index}
                onClick={() => setShowDownloadPopup(true)}
                className="bg-white rounded-lg overflow-hidden shadow-lg
                       transition-transform duration-300 hover:scale-105 
                       border border-gray-200 cursor-pointer
                       transform scale-[0.85] sm:scale-90 md:scale-95 2xl:scale-100"
              >
                {/* Image Container */}
                <div className="aspect-[3/2] relative">
                  <img
                    src={item.imageUrl}
                    alt={item.storeName}
                    className="w-full h-full object-cover"
                  />
                </div>

                {/* Content Container */}
                <div className="p-3 sm:p-4 2xl:p-5">
                  <div className="flex items-center justify-between">
                    <div className="flex-1">
                      <h3 className="text-sm sm:text-base lg:text-lg 2xl:text-xl 
                                 text-gray-900 font-medium truncate">
                        {item.storeName}
                      </h3>
                      <p className="text-xs sm:text-sm lg:text-base 2xl:text-lg 
                                 text-gray-500 mt-0.5 2xl:mt-1">
                        {item.description}
                      </p>
                    </div>
                    {item.brandLogo && (
                      <img
                        src={item.brandLogo}
                        alt={`${item.storeName} logo`}
                        className="w-6 h-6 sm:w-8 sm:h-8 lg:w-10 lg:h-10 2xl:w-12 2xl:h-12 
                               object-contain rounded-md ml-2 sm:ml-3 2xl:ml-4"
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Download App Popup - Rendered through portal */}
      {showDownloadPopup && createPortal(
        <DownloadAppPopup 
          isOpen={showDownloadPopup} 
          onClose={() => setShowDownloadPopup(false)} 
        />,
        document.getElementById('portal-root')
      )}
    </>
  );
};

export default BrandsGrid;
