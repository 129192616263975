import React from 'react';
import { Link } from 'react-router-dom';
import { Send, Youtube, Instagram, Facebook, Linkedin } from 'lucide-react';
import playstoreLogo from "../assets/playstore.png";
import appstoreLogo from "../assets/app-store.png";
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

const Footer = () => {
  const navigationItems = [
    { name: 'Brands', path: '/brands' },
    { name: 'Privacy Policy', path: '/tnc' },
    { name: 'Terms & Conditions', path: '/tnc' },
    { name: 'Return Policy', path: '/tnc' },
    { name: 'Cancellation and Refund', path: '/tnc' },
    { name: 'Shipping and Delivery', path: '/tnc' },
  ];

  return (
    <footer className="bg-white text-black py-8 2xl:py-12">
      <div className="py-6 md:py-12 px-4 sm:px-6">
        <div className="w-[80%] mx-auto">
          <h2 className="text-3xl md:text-4xl 2xl:text-5xl font-bold mb-12 2xl:mb-16 text-center">
            See it, Love it, Shop it
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-10 2xl:gap-16 items-start">
            {/* First Column - ZULU Club */}
            <div className="space-y-6 2xl:space-y-8 flex flex-col items-center">
              <h3 className="text-black text-xl 2xl:text-2xl font-semibold">Zulu Club</h3>
              <div className="flex flex-col gap-4 2xl:gap-6">
                <button 
                  onClick={() => window.open('https://play.google.com/store/apps/details?id=com.zulu.consumer.zulu_consumer', '_blank')}
                  className="bg-gray-200 text-gray-800 px-6 py-4 2xl:px-8 2xl:py-5 
                           rounded-lg font-semibold border border-gray-300 
                           hover:bg-gray-300 transition-colors 
                           flex items-center gap-4 2xl:gap-6"
                >
                  <div className="w-12 h-12 2xl:w-14 2xl:h-14 rounded-full bg-gray-800 
                              flex items-center justify-center flex-shrink-0">
                    <svg className="w-7 h-7 2xl:w-8 2xl:h-8 text-white" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M3,20.5V3.5C3,2.91 3.34,2.39 3.84,2.15L13.69,12L3.84,21.85C3.34,21.6 3,21.09 3,20.5M16.81,15.12L6.05,21.34L14.54,12.85L16.81,15.12M20.16,10.81C20.5,11.08 20.75,11.5 20.75,12C20.75,12.5 20.5,12.92 20.16,13.19L17.89,14.5L15.39,12L17.89,9.5L20.16,10.81M6.05,2.66L16.81,8.88L14.54,11.15L6.05,2.66Z"/>
                    </svg>
                  </div>
                  <div className="text-left">
                    <div className="text-xs 2xl:text-sm text-gray-600">Get it on</div>
                    <div className="text-sm 2xl:text-base font-semibold">Google Play</div>
                  </div>
                </button>

                <button 
                  onClick={() => window.open('https://apps.apple.com/in/app/zulu-club/id6739531325', '_blank')}
                  className="bg-gray-200 text-gray-800 px-6 py-4 2xl:px-8 2xl:py-5 
                           rounded-lg font-semibold border border-gray-300 
                           hover:bg-gray-300 transition-colors 
                           flex items-center gap-4 2xl:gap-6"
                >
                  <div className="w-12 h-12 2xl:w-14 2xl:h-14 rounded-full bg-gray-800 
                              flex items-center justify-center flex-shrink-0">
                    <svg className="w-7 h-7 2xl:w-8 2xl:h-8 text-white" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11"/>
                    </svg>
                  </div>
                  <div className="text-left">
                    <div className="text-xs 2xl:text-sm text-gray-600">Get it on the</div>
                    <div className="text-sm 2xl:text-base font-semibold">App Store</div>
                  </div>
                </button>
              </div>
            </div>

            {/* Second Column - Navigation */}
            <div className="space-y-4 2xl:space-y-6 flex flex-col items-center">
              <h3 className="text-black text-xl 2xl:text-2xl font-semibold">Navigation</h3>
              <ul className="space-y-3 2xl:space-y-4 text-center">
                {navigationItems.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.path}
                      className="text-gray-400 hover:text-black cursor-pointer text-base 2xl:text-lg"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Third Column - Contact */}
            <div className="space-y-4 2xl:space-y-6 flex flex-col items-center">
              <h3 className="text-black text-xl 2xl:text-2xl font-semibold">Contact</h3>
              <div className="space-y-3 2xl:space-y-4 text-gray-400 text-center">
                <p className="text-base 2xl:text-lg">hi@zulu.club</p>
                <p className="text-base 2xl:text-lg">+91 85279 20080</p>
                <p className="text-base 2xl:text-lg">Golf Course Extn Road,</p>
                <p className="text-base 2xl:text-lg">Gurgaon,</p>
                <p className="text-base 2xl:text-lg">Haryana, India</p>
                <a 
                  href="https://www.instagram.com/zuluclubindia?igsh=YWg4ZXFnenFzMWM4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 justify-center hover:text-black transition-colors text-base 2xl:text-lg"
                >
                  <Instagram size={20} className="2xl:w-6 2xl:h-6" />
                  <span>Follow us on Instagram</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 text-center mt-8 2xl:mt-12 pt-4 border-t border-gray-400">
        <p className="text-gray-400 text-sm 2xl:text-base">
          MADMIND TECH INNOVATIONS PRIVATE LIMITED 
        </p>
      </div>
    </footer>
  );
};

export default Footer;
