import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import mallvideo from '../assets/malls1.mp4';
import DownloadAppPopup from './DownloadAppPopup';

const RunMall = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  // Brand items data
  const brandItems = [
    {
      storeName: "Zara Ambience Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/10001835651.webp",
      brandLogo: "https://zulushop.in/uploads/seller/Zara_Logo1.png",
    },
    {
      storeName: "Uniqlo Ambience Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000183708.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/UNIQLO_Logo.png",
    },
    {
      storeName: "H & M Ambience Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000183604.webp",
      brandLogo: "https://zulushop.in/uploads/seller/H_M_Logo.png",
    },
    {
      storeName: "House of Fett Ardee Mall",
      description: "Women",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000139146.jpg",       
      brandLogo: "https://zulushop.in/uploads/seller/House_of_Fett_Logo.png",
    },
    {
      storeName: "Skechers Airia Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000139511.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/Skechers1.png",
    },
    {
      storeName: "Jack & Jones Airia Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000105421.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/Jack_Jones_logo.png",
    },
    
  ];

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Mobile Layout
  const MobileLayout = () => (
    <div className="bg-white py-20 sm:py-8">
      <div className="container mx-auto px-4">
        {/* Mobile Text Content */}
        <div className="text-center mb-6">
          <div className="p-6">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-3">
              Discover fashion<br/> near you
            </h2>
            
            <p className="text-base sm:text-lg text-gray-700 mb-4 leading-relaxed">
            Explore the best shopping <br></br> destinations across NCR
            </p>

            <button 
              className="bg-gray-900 text-white font-semibold 
                       py-2 sm:py-2.5 px-4 sm:px-6
                       rounded-lg border border-gray-700
                       transition-all duration-300 ease-in-out
                       text-sm sm:text-base"
              onClick={() => setIsOpen(true)}
            >
              Get Started
            </button>
          </div>
        </div>

        {/* Mobile Video Card - Fixed Centering */}
        <div className="flex justify-center items-center w-full">
          <div className="w-[280px] sm:w-[300px] md:w-[320px]"> {/* Fixed widths for different breakpoints */}
            <div className="aspect-[3/4] relative
                          rounded-xl overflow-hidden
                          bg-white/5 backdrop-blur-sm border border-white">
              <video
                src={mallvideo}
                className="w-full h-full object-contain"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Desktop Layout
  return (
    <>
      

      {isMobile ? (
        <MobileLayout />
      ) : (
        <div className="flex h-[100vh] xl:translate-y-[-1%] 2xl:translate-y-[5%]">
          {/* Left White Section - 50% */}
          <div className="w-1/2 bg-white min-h-screen flex items-center justify-end">
            <div className="w-full  lg:w-[600px] 2xl:w-[800px] pr-20 2xl:pr-32 xl:translate-x-[15%] 2xl:translate-x-[20%]">
              <div className="space-y-8 2xl:space-y-12">
                {/* Title with refined styling */}
                <h2 className="text-5xl 2xl:text-6xl font-bold text-gray-900 leading-tight translate-y-[-30%]">
                   Discover fashion<br/> near you
                </h2>
                
                {/* Description with refined styling */}
                <p className="text-xl 2xl:text-2xl text-gray-600 leading-relaxed font-poppins 
                            max-w-xl 2xl:max-w-2xl tracking-wide translate-y-[-25%]">
                  Explore the best shopping destinations <br></br> across NCR
                </p>

                {/* Modern button design */}
                <div className="pt-4 translate-y-[70%]">
                  <button 
                    className="group relative px-8 2xl:px-10 py-3.5 2xl:py-4
                             overflow-hidden rounded-xl 
                             bg-gray-900 hover:bg-gray-800
                             transition-all duration-300 ease-out
                             shadow-lg hover:shadow-xl"
                    onClick={() => setIsOpen(true)}
                  >
                    <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-white/0 via-white/5 to-white/0 
                                  translate-x-[-100%] group-hover:translate-x-[100%] 
                                  transition-transform duration-700" />
                    <span className="relative font-medium text-lg 2xl:text-xl text-white tracking-wide">
                      Get Started
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Right Dark Section - 50% */}
          <div className="w-1/2 bg-white min-h-screen flex items-center">
            <div className="w-full lg:w-[600px] 2xl:w-[800px] pl-20 2xl:pl-32">
              {/* Video Content */}
              <div className="relative aspect-[3/4] w-[300px] 2xl:w-[400px] 
                            h-[500px] 2xl:h-[640px] 
                            lg:translate-x-[25%] xl:translate-x-[25%] 2xl:translate-x-[40%]">
                <div className="relative w-full h-full rounded-2xl overflow-hidden 
                              bg-white/5 backdrop-blur-sm border border-white">
                  <video
                    src={mallvideo}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DownloadAppPopup isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default RunMall; 