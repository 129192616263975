import React, { useState, useEffect } from 'react';
import video1 from '../assets/Promotion1.mp4';
import video2 from '../assets/Promotion2.mp4';
import video3 from '../assets/Promotion3.mp4';
import video4 from '../assets/Promotion4.mp4';
import video5 from '../assets/Promotion5.mp4';
import { Volume2, VolumeX } from 'lucide-react'; // Import icons

const RunReels = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [hoveredVideo, setHoveredVideo] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Add window resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Placeholder video data - replace with actual videos later
  const reels = [
    {
      id: 1,
      video: video2,
      
      
    },
    {
      id: 2,
      video: video4,
      
      
    },
    {
      id: 3,
      video: video3,
      
      
    },
    {
      id: 4,
      video: video1,
      
      
    }
  ];

  const handleVideoClick = (reel) => {
    setSelectedVideo(reel);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

  const handleVideoHover = (event, isHovering, reelId) => {
    // Skip hover handling for first video
    if (reelId === 1) return;

    // Find the video element safely
    const videoElement = event.currentTarget.querySelector('video');
    if (!videoElement) return; // Guard clause

    if (isHovering) {
      setHoveredVideo(reelId);
      try {
        videoElement.currentTime = 0;
        videoElement.play().catch(err => console.log('Autoplay prevented:', err));
      } catch (error) {
        console.log('Video interaction error:', error);
      }
    } else {
      setHoveredVideo(null);
      try {
        videoElement.pause();
        videoElement.currentTime = 0;
      } catch (error) {
        console.log('Video interaction error:', error);
      }
    }
  };

  return (
    <div className="relative py-8 sm:py-12 lg:py-24 2xl:py-40 
                    px-4 sm:px-6 lg:px-8 bg-white translate-y-[5%] xl:translate-y-[-10%] 2xl:translate-y-[5%]">
      {/* Content with relative positioning */}
      <div className="relative z-10 max-w-7xl 2xl:max-w-[1400px] mx-auto">
        {/* Section Header - Center aligned */}
        <div className="mb-8 sm:mb-10 lg:mb-12 2xl:mb-16 text-center">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl 2xl:text-5xl 
                       font-bold text-gray-900 
                       mb-9 sm:mb-8 lg:mb-10 2xl:mb-12 
                       ">
            # DO THE ZULU
          </h2>
          <h2 className="text-xl sm:text-2xl lg:text-2xl 2xl:text-3xl 
                        text-gray-700 
                       translate-y-[-50%]">
             Fashion that meets your lifestyle, instantly!
          </h2>
        </div>

        {/* Horizontal Scrolling Reels */}
        <div className="relative max-w-full overflow-x-auto 
                      pb-6 sm:pb-8 lg:pb-10 2xl:pb-12
                      translate-x-0 sm:translate-x-[5%] lg:translate-x-[10%] 2xl:translate-x-[5%]">
          <div className="flex gap-4 sm:gap-6 lg:gap-8 2xl:gap-10 min-w-max px-4 sm:px-0">
            {reels.map((reel) => (
              <div 
                key={reel.id}
                onClick={() => handleVideoClick(reel)}
                onMouseEnter={(e) => handleVideoHover(e, true, reel.id)}
                onMouseLeave={(e) => handleVideoHover(e, false, reel.id)}
                onTouchStart={(e) => handleVideoHover(e, true, reel.id)}
                onTouchEnd={(e) => handleVideoHover(e, false, reel.id)}
                className="relative w-[160px] sm:w-[200px] lg:w-[232px] 2xl:w-[280px] 
                         aspect-[9/16] rounded-xl overflow-hidden
                         bg-white/5 backdrop-blur-sm border border-white/10
                         transform transition duration-300 hover:scale-[1.02]
                         cursor-pointer flex-shrink-0"
              >
                {/* Video Element */}
                <video
                  className="w-full h-full object-cover"
                  src={reel.video}
                  loop
                  muted
                  playsInline
                  autoPlay={reel.id === 1}
                />

                {/* Overlay with gradient */}
                <div className={`absolute inset-0 bg-gradient-to-t 
                              from-black/90 via-black/50 to-transparent
                              transition-opacity duration-300
                              ${hoveredVideo === reel.id || reel.id === 1 ? 'opacity-50' : 'opacity-100'}`}>
                  {/* Video Info */}
                  <div className="absolute bottom-4 sm:bottom-5 lg:bottom-6 2xl:bottom-8 
                                left-4 sm:left-5 lg:left-6 2xl:left-8 
                                right-4 sm:right-5 lg:right-6 2xl:right-8">
                    <h3 className="text-base sm:text-lg lg:text-xl 2xl:text-2xl 
                                font-bold text-white mb-2 2xl:mb-3">
                      {reel.title}
                    </h3>
                    <div className="flex items-center gap-2 sm:gap-3">
                      <span className="text-xs sm:text-sm lg:text-base 2xl:text-lg text-gray-300">
                        {reel.views}
                      </span>
                      {/* Play Button */}
                      <button 
                        className="bg-white/10 backdrop-blur-sm rounded-full 
                                p-1.5 sm:p-2 lg:p-2.5 2xl:p-3
                                hover:bg-white/20 transition-colors duration-300"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleVideoClick(reel);
                        }}
                      >
                        <svg 
                          className="w-3 h-3 sm:w-4 sm:h-4 lg:w-5 lg:h-5 2xl:w-6 2xl:h-6 text-white" 
                          fill="currentColor" 
                          viewBox="0 0 24 24"
                        >
                          <path d="M8 5v14l11-7z"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Video Modal - Adjusted for all screen sizes */}
        {selectedVideo && (
          <div 
            className="fixed inset-0 bg-black/80 flex items-center justify-center z-50 p-4"
            onClick={closeModal}
          >
            <div 
              className="relative w-full max-w-[90vw] sm:max-w-[85vw] lg:max-w-[75vw] 2xl:max-w-[65vw]"
              onClick={(e) => e.stopPropagation()}
            >
              <button 
                onClick={closeModal}
                className="absolute -top-8 sm:-top-10 lg:-top-12 2xl:-top-14 right-0 
                        text-white/80 hover:text-white
                        text-base sm:text-lg lg:text-xl 2xl:text-2xl font-semibold"
              >
                Close
              </button>
              <div className="aspect-video">
                <video 
                  controls 
                  autoPlay 
                  className="w-full h-full rounded-lg object-contain
                         shadow-lg shadow-black/50"
                >
                  <source src={selectedVideo.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RunReels;
