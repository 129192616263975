import React from 'react';
import discoverImage from '../assets/68.png';
import deliveryImage from '../assets/69.png';
import videoTourImage from '../assets/70.png';
import windowShop from '../assets/windowshop.mp4';
import videoTour from '../assets/video_tour.mp4';
import deliveryreturns from '../assets/delivery_return.mp4';
const ExperienceZulu = () => {
  const features = [
    {
      id: 1,
      title: 'WINDOW SHOP',
      subtitle: 'Explore as much as you want, with no commitment to buy',
      type: 'video',
      content: windowShop,
      buttonClass: 'bg-white/10',
    },
    {
      id: 2,
      title: 'VIDEO TOUR',
      subtitle: 'Instantly tour malls & outlets to find what you are looking for',
      type: 'video',
      content: videoTour,
      buttonClass: 'bg-white/10',
    },
    {
      id: 3,
      title: 'DELIVERY & RETURNS',
      subtitle: 'Get it within 100 mins, return or exchange as per outlet policy',
      type: 'video',
      content: deliveryreturns,
      buttonClass: 'bg-white/10',
    },
  ];

  return (
    <div className="bg-white py-16 px-4 sm:px-6 lg:px-18 2xl:py-30 translate-y-[0%] xl:translate-y-[-23%] 2xl:translate-y-[-5%]">
      <div className="max-w-7xl 2xl:max-w-[1400px] mx-auto">
        <h1 className="text-3xl md:text-4xl font-bold text-black text-center mb-4 2xl:text-5xl 2xl:mb-6">
          Experience Zulu
        </h1>
        <p className="text-center text-lg md:text-xl text-gray-700 font-light mb-10 2xl:text-2xl 2xl:mb-12">
          A new way to shop online
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 2xl:gap-10">
          {features.map((feature) => (
            <div
              key={feature.id}
              className="relative transform scale-90"
            >
              <div className="relative rounded-lg overflow-hidden bg-white/5 border border-white/10">
                {feature.type === 'video' ? (
                  <video
                    src={feature.content}
                    className="w-full h-[290px] md:h-[340px] 2xl:h-[400px] object-cover"
                    autoPlay
                    loop
                    muted
                    playsInline
                  />
                ) : (
                  <img
                    src={feature.content}
                    alt={feature.title}
                    className="w-full h-[290px] md:h-[340px] 2xl:h-[400px] object-cover"
                  />
                )}
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent">
                  <div className="absolute top-4 right-4 2xl:top-6 2xl:right-6">
                    <div className={`${feature.buttonClass} backdrop-blur-sm text-white px-4 py-2 rounded-lg text-sm font-medium 2xl:px-6 2xl:py-3 2xl:text-base`}>
                      {feature.buttonText}
                    </div>
                  </div>
                  <div className="absolute inset-x-0 bottom-0 p-6 2xl:p-8">
                    <h2 className="text-xl md:text-2xl font-bold mb-2 text-white 2xl:text-3xl 2xl:mb-3">
                      {feature.title}
                    </h2>
                    <p className="text-sm md:text-base text-gray-300 2xl:text-lg">
                      {feature.subtitle}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExperienceZulu;
