import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IoClose, IoMenu } from 'react-icons/io5';
import { IoSearchOutline } from 'react-icons/io5';
import { Zap } from 'lucide-react';
import { IoBagOutline, IoHeartOutline, IoGridOutline } from 'react-icons/io5';

const ShopNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeCategory, setActiveCategory] = useState('men');

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300
                    ${isScrolled ? 'bg-black/80 backdrop-blur-xl' : 'bg-transparent'}`}>
      <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-24 sm:h-30">
          {/* Logo Section - Desktop Only */}
          <div className="flex-shrink-0 hidden md:flex items-center gap-3">
            <Link to="/" className="flex-shrink-0">
              <span className="text-white text-2xl translate-y-[15%] font-extrabold tracking-wider hover:opacity-80 transition-opacity duration-200
                            xl:text-2xl 2xl:text-2xl font-montserrat">
                ZULU
              </span>
            </Link>
            <span className="text-white text-sm flex items-center gap-1 font-small transition-all duration-300
                          xl:text-sm 2xl:text-sm">
              Fashion in <Zap size={14} className="text-red-500 fill-red-500 w-3 h-3" /> 100 mins
            </span>
          </div>

          {/* Mobile Search Bar */}
          <div className="md:hidden flex-1 mx-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search for products, brands or more"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full bg-white/30 text-white placeholder-white/50
                         border border-white/10 rounded-sm
                         py-2 pl-4 pr-10 text-sm
                         focus:outline-none focus:border-white/30
                         transition-all duration-300"
              />
              <button 
                className="absolute right-3 top-1/2 -translate-y-1/2
                         text-white/50 hover:text-white
                         transition-colors duration-300"
              >
                <IoSearchOutline className="text-lg" />
              </button>
            </div>
          </div>

          {/* Center Section: Search and Categories - Desktop */}
          <div className="hidden md:flex flex-col items-center justify-center mx-4">
            <div className="relative w-[400px] xl:w-[500px] 2xl:w-[600px]">
              <input
                type="text"
                placeholder="Search for products, brands or more"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full bg-white/10 text-white placeholder-white/50
                         border border-white/10 rounded-sm
                         py-1.5 pl-5 pr-12
                         focus:outline-none focus:border-white/30
                         transition-all duration-300
                         text-sm"
              />
              <button 
                className="absolute right-4 top-1/2 -translate-y-1/2
                         text-white/50 hover:text-white
                         transition-colors duration-300"
              >
                <IoSearchOutline className="text-lg" />
              </button>
            </div>
            
            {/* <div className="flex justify-center space-x-8 mt-2 translate-y-[50%]">
              <button
                onClick={() => setActiveCategory('men')}
                className={`text-xs font-medium px-4 py-0.5 rounded-full transition-all duration-300
                         ${activeCategory === 'men' 
                           ? 'bg-white/10 text-white' 
                           : 'text-white/70 hover:text-white'}`}
              >
                MEN
              </button>
              <button
                onClick={() => setActiveCategory('women')}
                className={`text-xs font-medium px-4 py-0.5 rounded-full transition-all duration-300
                         ${activeCategory === 'women' 
                           ? 'bg-white/10 text-white' 
                           : 'text-white/70 hover:text-white'}`}
              >
                WOMEN
              </button>
              <button
                onClick={() => setActiveCategory('kids')}
                className={`text-xs font-medium px-4 py-0.5 rounded-full transition-all duration-300
                         ${activeCategory === 'kids' 
                           ? 'bg-white/10 text-white' 
                           : 'text-white/70 hover:text-white'}`}
              >
                KIDS
              </button>
            </div> */}
          </div>

          {/* Right Section: Navigation - Desktop */}
          <div className="hidden md:flex items-center space-x-8">
            <Link
              to="/categories"
              className="text-white/80 hover:text-white text-sm font-medium
                       transition-colors duration-300 flex items-center gap-2"
            >
              <IoGridOutline className="text-xl" />
              <span>Categories</span>
            </Link>
            <Link
              to="/wishlist"
              className="text-white/80 hover:text-white text-sm font-medium
                       transition-colors duration-300 flex items-center gap-2"
            >
              <IoHeartOutline className="text-xl" />
              <span>Wishlist</span>
            </Link>
            <Link
              to="/cart"
              className="text-white/80 hover:text-white text-sm font-medium
                       transition-colors duration-300 flex items-center gap-2"
            >
              <IoBagOutline className="text-xl" />
              <span>Cart</span>
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden text-white p-2"
            aria-label="Toggle menu"
          >
            {isMenuOpen ? <IoClose className="text-2xl" /> : <IoMenu className="text-2xl" />}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden absolute top-full left-0 right-0 
                        bg-black/90 backdrop-blur-md border-t border-white/5">
            {/* Mobile Category Buttons */}
            <div className="px-4 py-3 border-b border-white/5">
              <div className="flex justify-between space-x-2">
                <button
                  onClick={() => setActiveCategory('men')}
                  className={`flex-1 text-sm font-medium py-1.5 rounded-full 
                           transition-all duration-300 border
                           ${activeCategory === 'men' 
                             ? 'bg-white/20 text-white border-white/30' 
                             : 'text-white/80 hover:text-white border-white/10 hover:bg-white/10'}`}
                >
                  MEN
                </button>
                <button
                  onClick={() => setActiveCategory('women')}
                  className={`flex-1 text-sm font-medium py-1.5 rounded-full 
                           transition-all duration-300 border
                           ${activeCategory === 'women' 
                             ? 'bg-white/20 text-white border-white/30' 
                             : 'text-white/80 hover:text-white border-white/10 hover:bg-white/10'}`}
                >
                  WOMEN
                </button>
                <button
                  onClick={() => setActiveCategory('kids')}
                  className={`flex-1 text-sm font-medium py-1.5 rounded-full 
                           transition-all duration-300 border
                           ${activeCategory === 'kids' 
                             ? 'bg-white/20 text-white border-white/30' 
                             : 'text-white/80 hover:text-white border-white/10 hover:bg-white/10'}`}
                >
                  KIDS
                </button>
              </div>
            </div>

            {/* Mobile Navigation Links */}
            <div className="px-4 py-3 space-y-3">
              <Link
                to="/cart"
                className="block text-white/80 hover:text-white text-sm font-medium
                         transition-colors duration-300 flex items-center gap-2"
                onClick={() => setIsMenuOpen(false)}
              >
                <IoBagOutline className="text-xl" />
                <span>Cart</span>
              </Link>
              <Link
                to="/wishlist"
                className="block text-white/80 hover:text-white text-sm font-medium
                         transition-colors duration-300 flex items-center gap-2"
                onClick={() => setIsMenuOpen(false)}
              >
                <IoHeartOutline className="text-xl" />
                <span>Wishlist</span>
              </Link>
              <Link
                to="/categories"
                className="block text-white/80 hover:text-white text-sm font-medium
                         transition-colors duration-300 flex items-center gap-2"
                onClick={() => setIsMenuOpen(false)}
              >
                <IoGridOutline className="text-xl" />
                <span>Categories</span>
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default ShopNavbar; 