import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import DownloadAppPopup from './DownloadAppPopup';

const Offer = () => {
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);

  return (
    <>
      <div 
        onClick={() => setShowDownloadPopup(true)}
        className="w-full bg-black text-white py-4 xl:py-5 2xl:py-7 text-center font-medium
                  absolute sm:top-[92vh] top-[94vh] left-0 right-0 z-[45]
                  cursor-pointer  transition-all duration-300"
      >
        <div className="flex items-center justify-center space-x-2">
          <span className="text-[14px] xl:text-[14px]">
            DOWNLOAD OUR APP
          </span>
          {/* Optional: Add a small icon or arrow */}
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-2.5 w-2.5" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M9 5l7 7-7 7" 
            />
          </svg>
        </div>
      </div>

      {/* Download App Popup */}
      {showDownloadPopup && createPortal(
        <DownloadAppPopup 
          isOpen={showDownloadPopup} 
          onClose={() => setShowDownloadPopup(false)}
        />,
        document.getElementById('portal-root')
      )}
    </>
  );
};

export default Offer;
