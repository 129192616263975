import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchSpecificProducts } from '../services/api';

// Loading State Component
const LoadingState = () => (
  <section className="py-16 bg-black">
    <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-center min-h-[300px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
      </div>
    </div>
  </section>
);

// Error State Component
const ErrorState = ({ error }) => (
  <section className="py-16 bg-black">
    <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col items-center justify-center min-h-[300px]">
        <p className="text-red-500 text-center mb-4">{error}</p>
        <button 
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-white/10 hover:bg-white/20 text-white rounded-lg
                   transition-colors duration-200"
        >
          Try Again
        </button>
      </div>
    </div>
  </section>
);

// Empty State Component
const EmptyState = () => (
  <section className="py-16 bg-black">
    <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-center min-h-[300px]">
        <p className="text-white/70">No products found</p>
      </div>
    </div>
  </section>
);

const ProductGallery = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await fetchSpecificProducts();
        const processedProducts = data.map(product => {
          const variant = product.variants && product.variants[0];
          return {
            ...product,
            price: variant ? variant.price : '0',
            special_price: variant ? variant.special_price : '0'
          };
        });
        
        // Take only first 6 products for one row
        setProducts(processedProducts.slice(0, 6));
      } catch (err) {
        console.error('Error loading products:', err);
        setError(err.message || 'Failed to load products');
      } finally {
        setLoading(false);
      }
    };

    loadProducts();
  }, []);

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;
  if (!products.length) return <EmptyState />;

  return (
    <section className="py-16 bg-black">
      <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl md:text-3xl font-bold text-white mb-8">
          Featured Products
        </h2>
        
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
          {products.map((product) => {
            const regularPrice = parseInt(product.price) || 0;
            const specialPrice = parseInt(product.special_price) || 0;
            const hasDiscount = specialPrice > 0 && specialPrice < regularPrice;

            return (
              <div key={product.id} className="group">
                <Link
                  to={`/product/${product.id}`}
                  className="block relative aspect-[3/4] overflow-hidden rounded-xl mb-4"
                >
                  <img
                    src={product.image_url || product.image}
                    alt={product.name}
                    className="w-full h-full object-cover transition-transform duration-500 
                             group-hover:scale-110"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = 'https://via.placeholder.com/300x400?text=Product+Image';
                    }}
                  />
                  <div className="absolute inset-0 bg-black/10 group-hover:bg-black/20 
                              transition-colors duration-300" />
                </Link>
                
                <div className="space-y-2">
                  {product.brand && (
                    <Link 
                      to={`/brand/${encodeURIComponent(product.brand)}`}
                      className="block text-white/70 text-sm font-medium uppercase tracking-wider
                               hover:text-white transition-colors duration-200"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {product.brand}
                    </Link>
                  )}
                  
                  <Link 
                    to={`/product/${product.id}`}
                    className="block text-white font-medium group-hover:text-white/80 
                             transition-colors duration-300 line-clamp-2"
                  >
                    {product.name}
                  </Link>
                  
                  <div className="flex flex-col gap-1">
                    {hasDiscount ? (
                      <>
                        <div className="flex items-center gap-2">
                          <span className="text-white font-medium">
                            ₹{specialPrice.toLocaleString()}
                          </span>
                          <span className="text-white/70 text-sm line-through">
                            ₹{regularPrice.toLocaleString()}
                          </span>
                        </div>
                        <span className="text-green-500 text-xs">
                          {Math.round(((regularPrice - specialPrice) / regularPrice) * 100)}% off
                        </span>
                      </>
                    ) : (
                      <span className="text-white font-medium">
                        ₹{regularPrice.toLocaleString()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ProductGallery;
