import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchSellers } from '../services/api';
import brandsMapping from '../data/brandsmapping.json';

// Loading State Component
const LoadingState = () => (
  <section className="py-8 bg-black">
    <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
      </div>
    </div>
  </section>
);

// Error State Component
const ErrorState = ({ error }) => (
  <section className="py-8 bg-black">
    <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col items-center justify-center min-h-[200px]">
        <p className="text-red-500 text-center mb-4">{error}</p>
        <button 
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-white/10 hover:bg-white/20 text-white rounded-lg
                   transition-colors duration-200"
        >
          Try Again
        </button>
      </div>
    </div>
  </section>
);

const BrandsGallery = ({ selectedCategory = 'MEN' }) => {
  const [allBrands, setAllBrands] = useState([]);
  const [displayedBrands, setDisplayedBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Load all brands once
  useEffect(() => {
    const loadBrands = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await fetchSellers();
        
        // Process the brands data to parse image URLs
        const processedBrands = data.map(brand => {
          console.log('Raw seller_profile:', brand.seller_profile); // Debug log

          try {
            let imageUrl = '';
            if (brand.seller_profile) {
              const imageData = JSON.parse(brand.seller_profile);
              console.log('Parsed imageData:', imageData); // Debug log

              // Handle different JSON structures
              if (typeof imageData === 'string') {
                imageUrl = imageData;
              } else if (Array.isArray(imageData)) {
                imageUrl = imageData[0]?.url || imageData[0] || '';
              } else if (imageData.url) {
                imageUrl = imageData.url;
              }
            }

            return {
              ...brand,
              seller_profile: imageUrl || brand.seller_profile || ''
            };
          } catch (e) {
            console.log('Error parsing image for brand:', brand.seller_name, e);
            return brand;
          }
        });

        console.log('Processed brands:', processedBrands); // Debug log
        setAllBrands(processedBrands);
      } catch (err) {
        console.error('Error loading brands:', err);
        setError(err.message || 'Failed to load brands');
      } finally {
        setLoading(false);
      }
    };

    loadBrands();
  }, []);

  // Filter brands when category changes or when all brands are loaded
  useEffect(() => {
    if (allBrands.length > 0) {
      const categoryBrands = brandsMapping[selectedCategory] || [];
      const brandIds = categoryBrands.map(item => item.brand_id);
      
      const filteredBrands = allBrands.filter(seller => 
        brandIds.includes(seller.seller_id.toString())
      );

      const limitedBrands = filteredBrands.slice(0, 5);
      setDisplayedBrands(limitedBrands);
    }
  }, [selectedCategory, allBrands]);

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;
  if (!displayedBrands || displayedBrands.length === 0) return (
    <section className="py-12 bg-black">
      <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-center min-h-[200px]">
          <p className="text-white/70">No brands available for {selectedCategory}</p>
        </div>
      </div>
    </section>
  );

  return (
    <section className="py-12 bg-black">
      <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl md:text-3xl font-bold text-white mb-8">
          Featured Brands - {selectedCategory}
        </h2>
        
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {displayedBrands.map((brand) => {
            console.log('Rendering brand with image:', brand.seller_profile); // Debug log

            return (
              <Link
                key={brand.seller_id}
                to={`/brand/${brand.seller_id}`}
                className="group block"
              >
                <div className="relative aspect-square overflow-hidden rounded-xl bg-white/5 
                            hover:bg-white/10 transition-colors duration-300">
                  <div className="absolute inset-0 flex items-center justify-center p-6">
                    {brand.seller_profile ? (
                      <img
                        src={brand.seller_profile}
                        alt={brand.seller_name}
                        className="w-full h-full object-contain transition-transform duration-500 
                                 group-hover:scale-110"
                        onError={(e) => {
                          console.log('Image load error for:', brand.seller_name); // Debug log
                          e.target.onerror = null;
                          e.target.style.display = 'none';
                          e.target.parentElement.innerHTML = `
                            <div class="text-white text-xl font-bold text-center">
                              ${brand.seller_name}
                            </div>
                          `;
                        }}
                      />
                    ) : (
                      <div className="text-white text-xl font-bold text-center">
                        {brand.seller_name}
                      </div>
                    )}
                  </div>
                </div>
                
                <div className="mt-4 text-center">
                  <h3 className="text-white font-medium group-hover:text-white/80 
                               transition-colors duration-300">
                    {brand.seller_name}
                  </h3>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BrandsGallery;
