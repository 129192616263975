import React, { useState, useRef, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Offer from '../components/Offer';
// import HeroSection from '../components/HeroSection'; // Commented out
import RunMall from '../components/RunMall';
import RunVideoTour from '../components/RunVideoTour';
import RunAskZulu from '../components/RunAskZulu';
import GetStartedSection from '../components/GetStartedSection';
import MarketsGrid from '../components/MarketsGrid';
import BrandsGrid from '../components/BrandsGrid';
import Footer from '../components/Footer';
import ExperienceZulu from '../components/ExperienceZulu';
import FAQ from '../components/FAQ';
import LatestCollections from '../components/LatestCollections';
import FeaturedMalls from '../components/FeaturedMalls';
import TeamSection from '../components/TeamSection';
import ChatInterface from '../components/ChatInterface';
import VideoSection from '../components/VideoSection';
import RunMallCopy from '../components/RunMallCopy';
import ReactGA from 'react-ga4';
import Animation from '../components/Animation';
import ConciergeNavigation from '../components/ConciergeNavigation';
import DownloadAppPopup from '../components/DownloadAppPopup';


const ConsumerPage = () => {
  const [showDownloadPopup, setShowDownloadPopup] = useState(true);
  const heroRef = useRef(null);
  const marketsRef = useRef(null);
  const brandsRef = useRef(null);
  const latestCollectionsRef = useRef(null);
  const experienceRef = useRef(null);

  const scrollToHero = () => heroRef.current?.scrollIntoView({ behavior: 'smooth' });
  const scrollToMarkets = () => {
    if (marketsRef.current) {
      const yOffset = -80; // Adjust for header height
      const element = marketsRef.current;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      
      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
  };
  const scrollToBrands = () => brandsRef.current?.scrollIntoView({ behavior: 'smooth' });
  const scrollToExperience = () => experienceRef.current?.scrollIntoView({ behavior: 'smooth' });
  
  useEffect(() => {
    // Show popup every time component mounts
    setShowDownloadPopup(true);

    // Initialize GA4
    ReactGA.initialize('G-WQPMLR391T');
    
    // Send pageview
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
  }, []);

  return (
    <div className="overflow-x-hidden w-full">
      <div className="relative w-full">
        <div className="">
          <div className="">
            <div className="">
              <Navbar />
            </div>
          </div>
        </div>
        
        <Animation />
        <Offer />
        <RunMallCopy />
        
        <RunVideoTour />
        <RunAskZulu />
        <BrandsGrid />
        <VideoSection />

        <div className="mt-6 px-4">
          {/* <GetStartedSection
            onCardClick={[scrollToMarkets, scrollToBrands, scrollToExperience]}
          /> */}
        </div>

        <div className="mt-10 px-4">
          {/* <div ref={marketsRef}>
            <FeaturedMalls />
          </div> */}
        </div>

        <div ref={brandsRef} className="px-4">
          
        </div>

        <div ref={experienceRef} className="mt-10 px-4">
          <ExperienceZulu />
        </div>

        


        <div className="md:mt-[-150px] px-4">
          <FAQ />
        </div>
        {/* <ConciergeNavigation /> */}

        {/* <div className='mt-10'>
          <TeamSection />
        </div> */}

        {/* <ChatInterface /> */}

        <Footer />
      </div>

      {/* Download App Popup - Always shows on landing */}
      {showDownloadPopup && (
        <DownloadAppPopup 
          isOpen={showDownloadPopup} 
          onClose={() => setShowDownloadPopup(false)}
        />
      )}
    </div>
  );
};

export default ConsumerPage;
