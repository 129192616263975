import React from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

const FAQ = () => {
  const questions = [
    {
      question: 'What is Zulu Club?',
      answer: 'Zulu Club is your personal gateway to discovering and shopping from real, offline fashion stores—right on your phone. We connect you with local boutiques, renowned brand outlets, and emerging designers, all in one easy-to-use app. Consider us that stylish friend who always knows where to find the perfect outfit, minus the traffic and crowded malls.',
    },
    {
      question: 'What can I shop on Zulu Club?',
      answer: 'We’re all about fashion—from everyday wear to statement pieces. Whether you’re looking for a casual kurti, a chic blazer, or the latest sneaker drops, Zulu Club lets you browse and buy from authentic stores near you. Plus, if you’re hunting for that hard-to-find item, we’ll help you track it down.',
    },
    {
      question: 'Where is Zulu Club operational?',
      answer: 'We currently serve NCR (National Capital Region). If you’re within our coverage area, you can enjoy the convenience of shopping local stores through our app. Check the app or our website for up-to-date details on city coverage. If we’re not in your neighborhood yet, hang tight—we’re rolling out to new places soon!',
    },
    {
      question: 'How does the video tour feature work?',
      answer: 'Imagine a live, personal shopping trip without stepping outside. With our video tour, one of our store associates—or a Zulu shopper—walks you through the racks in real time, showing you outfits, giving you close-ups of fabric and fit, and answering questions on the spot. It’s basically a virtual store walk-through, so you can see exactly what you’re buying before hitting “Add to Cart.”',
    },
    {
      question: 'Is there a return/exchange policy?',
      answer: 'Each store has its own policy, but don’t worry—we’ll guide you every step of the way. If you need to return or exchange something, just reach out to us in the app or give us a call. We’ll coordinate with the store to help make the process as smooth and quick as possible.',
    },
    {
      question: 'Can I pick up my order from the outlet?',
      answer: 'Absolutely! If it’s more convenient for you to swing by and pick it up, we’ll help arrange that with the store. You’ll still enjoy all the perks of shopping via Zulu—like seeing product details and reserving your size—minus any extra delivery wait time.',
    },
    {
      question: 'Do I need a membership or sign-up to shop?',
      answer: 'Yes, you’ll need to create an account—and here’s the kicker: membership is completely free for our first 100,000 sign-ups. After that, we may introduce subscription options. So, if you want in on the no-charge VIP treatment, now’s the time!'
    },
    {
      question: 'How is Zulu Club different from other eCommerce platforms?',
      answer: 'Unlike typical online marketplaces, we’re all about real stores and a personal touch. Our video tours let you see items up close in real time, and we work directly with trusted local outlets and brands—no random or suspicious sellers. Basically, it’s the authenticity of offline shopping blended with the convenience of online.',
    },
    {
      question: 'What payment methods do you accept?',
      answer: 'We’re flexible! You can pay using credit/debit cards, UPI, net banking, or even cash on delivery where available. We want your shopping experience to be as hassle-free as possible, so pick whatever payment mode suits you best.',
    },
    {
      question: 'How do I get help if something goes wrong?',
      answer: 'Our support team is here to keep your shopping vibes positive. If you run into any hiccups—like a delayed order or a mismatch in size—reach out via the in-app chat, WhatsApp, or phone. We’ll sort out issues swiftly, whether that means coordinating with the store on your behalf or arranging a speedy exchange. Your satisfaction is our priority, period.',
    },
  ];

  return (
    <section className="py-16 bg-white mt-[5%] md:mt-[5%] xl:mt-[5%] 2xl:mt-[12%]">
      <div className="container mx-auto px-4 max-w-7xl 2xl:max-w-[1400px]">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-900 translate-y-[-30%] 2xl:text-5xl 2xl:mb-16">
          Frequently Asked Questions
        </h2>
        <div className="max-w-3xl mx-auto space-y-4 2xl:space-y-6">
          {questions.map((faq, index) => (
            <Disclosure key={index}>
              {({ open }) => (
                <div className="border border-gray-200 rounded-lg overflow-hidden">
                  <Disclosure.Button className="w-full px-6 py-4 2xl:px-8 2xl:py-5 
                                             text-left flex justify-between items-center 
                                             bg-gray-50 hover:bg-gray-100 
                                             transition-colors duration-200">
                    <span className="text-gray-900 font-medium text-base 2xl:text-xl">
                      {faq.question}
                    </span>
                    <ChevronUpIcon
                      className={`${
                        open ? 'transform rotate-180' : ''
                      } w-5 h-5 2xl:w-6 2xl:h-6 text-gray-600 transition-transform duration-200`}
                    />
                  </Disclosure.Button>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel className="px-6 py-4 2xl:px-8 2xl:py-6 
                                              bg-white text-gray-600 
                                              text-base 2xl:text-lg">
                      {faq.answer}
                    </Disclosure.Panel>
                  </Transition>
                </div>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
