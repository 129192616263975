import React, { useState, useEffect } from "react";
import zuluAnimation from '../assets/zuluanimation.gif'
import backgroundimage from '../assets/black-back.png'
import appintro from '../assets/zulu app.mp4'
import zuluimage from '../assets/zulu_herologo.png'
import shoppingvideo from '../assets/shoppingvid.mp4'
import animation from '../assets/animation_new.gif'
import slide1 from '../assets/A.mp4'
import slide2 from '../assets/B.mp4'
import slide3 from '../assets/C.mp4'
import slide4 from '../assets/D.mp4'
import zululogo from '../assets/zulubanner.png'
import bannervideo1 from '../assets/conciergevid1.mp4'
import bannervideo2 from '../assets/conciergevid2.mp4'
import bannervideo3 from '../assets/conciergevid3.mp4'
import bannervideo4 from '../assets/conciergevid4.mp4'
import bannervideo5 from '../assets/conciergevid5.mp4'
import zululogo2 from '../assets/zululogobanner.png'
import slideimage2 from '../assets/slide2image.png'
import slideimage3 from '../assets/slide3image.png'
import slideimage4 from '../assets/slide4image.png' 
import slideimage5 from '../assets/slide5image.png'

import newbanner2 from '../assets/conciergebanner2.png'
import newbanner3 from '../assets/conciergebanner3.png'
import newbanner4 from '../assets/conciergebanner4.png'
import newbanner5 from '../assets/conciergebanner5.png'
// Mobile-specific imports
import mobileSlide1 from '../assets/1mobile.mp4'
import mobileSlide2 from '../assets/2mobile.mp4'
import mobileSlide3 from '../assets/3mobile.mp4'
import mobileSlide4 from '../assets/4mobile.mp4'
import mobilebanner1 from '../assets/mobilebanner1.png'
import mobilebanner2 from '../assets/mobilebanner2.png'
import mobilebanner3 from '../assets/mobilebanner3.png'
import mobilebanner4 from '../assets/mobilebanner4.png'
import mobilebanner5 from '../assets/mobilebanner5.png'

const Animation = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  // Desktop slides
  const desktopSlides = [
    
    {
      type: 'video',
      content: bannervideo2,
      alt: 'Desktop Slide 2',
      banner: newbanner2
    },
    {
      type: 'video',
      content: bannervideo3,
      alt: 'Desktop Slide 3',
      banner: newbanner3
    },
    {
      type: 'video',
      content: bannervideo4,
      alt: 'Desktop Slide 4',
      banner: newbanner4
    },
    {
      type: 'video',
      content: bannervideo5,
      alt: 'Desktop Slide 5',
      banner: newbanner5
    }
  ];

  // Mobile slides
  const mobileSlides = [
    
    {
      type: 'video',
      content: bannervideo2,
      alt: 'Mobile Slide 2',
      banner: newbanner2
    },
    {
      type: 'video',
      content: bannervideo3,
      alt: 'Mobile Slide 3',
      banner: newbanner3
    },
    {
      type: 'video',
      content: bannervideo4,
      alt: 'Mobile Slide 4',
      banner: newbanner4
    },
    {
      type: 'video',
      content: bannervideo5,
      alt: 'Mobile Slide 5',
      banner: newbanner5
    }
  ];

  const slides = isMobile ? mobileSlides : desktopSlides;

  // Check for mobile view
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Auto slide effect
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => {
        // Check if we've reached the end of slides
        if (prevSlide >= slides.length - 1) {
          return 0; // Reset to first slide
        }
        return prevSlide + 1; // Move to next slide
      });
    }, 7000); // Changed from 5000 to 7000 (7 seconds per slide)
    return () => clearInterval(timer);
  }, [slides.length]); // Add slides.length as dependency

  const MobileSlideContainer = () => (
    <div className="flex h-[85vh] w-screen bg-black">
      <div className="w-full mx-auto flex items-center justify-center">
        <div className="relative w-full h-full overflow-hidden">
          {/* Main Slides Container */}
          <div className="absolute w-full h-full">
            {slides.map((slide, index) => (
              <div 
                key={index}
                className="absolute top-0 left-0 w-screen h-screen"
                style={{ 
                  opacity: currentSlide === index ? 1 : 0,
                  transition: 'opacity 800ms ease',
                  zIndex: currentSlide === index ? 1 : 0,
                  pointerEvents: currentSlide === index ? 'auto' : 'none'
                }}
              >
                <video
                  src={slide.content}
                  className="w-screen h-screen object-cover"
                  autoPlay
                  loop
                  muted
                  playsInline
                  onClick={() => setCurrentSlide(index)}
                />
                {/* Banner */}
                <div 
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-[65%] w-[110%]"
                  style={{
                    opacity: currentSlide === index ? 1 : 0,
                    transition: 'opacity 800ms ease'
                  }}
                >
                  <img 
                    src={slide.banner}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-auto object-contain opacity-90"
                  />
                </div>
              </div>
            ))}
          </div>
          
          {/* Slide Indicators - Adjusted position */}
          <div className="absolute bottom-16 left-0 right-0 flex justify-center space-x-2 z-50">
            {slides.map((_, index) => (
              <button
                key={index}
                className={`w-2 h-2 rounded-full transition-all duration-300 
                          ${currentSlide === index ? 'bg-white w-4' : 'bg-white/50'}`}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const DesktopSlideContainer = () => (
    <div className="flex h-[92vh] w-screen bg-black ">
      <div className="w-full mx-auto flex items-center justify-center">
        <div className="relative w-full h-full overflow-hidden">
          {/* Main Slides Container */}
          <div className="absolute w-full h-full">
            {slides.map((slide, index) => (
              <div 
                key={index}
                className="absolute top-0 left-0 w-screen h-screen"
                style={{ 
                  opacity: currentSlide === index ? 1 : 0,
                  transition: 'opacity 800ms ease',
                  zIndex: currentSlide === index ? 1 : 0,
                  // Remove visibility property to allow all slides to be rendered
                  pointerEvents: currentSlide === index ? 'auto' : 'none'
                }}
              >
                <video
                  src={slide.content}
                  className="w-screen h-screen object-cover"
                  autoPlay
                  loop
                  muted
                  playsInline
                  onClick={() => setCurrentSlide(index)}
                />
                {/* Banner */}
                <div 
                  className="absolute top-[48%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90%]"
                  style={{
                    opacity: currentSlide === index ? 1 : 0,
                    transition: 'opacity 800ms ease'
                  }}
                >
                  <img 
                    src={slide.banner}
                    alt={`Slide ${index + 1}`}
                    className="w-[500px] xl:w-[800px] 2xl:w-[1000px]
                              transition-all duration-300 object-contain 
                              xl:translate-x-[0] opacity-90 2xl:translate-x-[0%]
                              relative z-10 mx-auto"
                  />
                </div>
              </div>
            ))}
          </div>
          
          {/* Slide Indicators - Changed from fixed to absolute positioning */}
          <div className="absolute bottom-16 left-0 right-0 flex justify-center space-x-2 z-50">
            {slides.map((_, index) => (
              <button
                key={index}
                className={`w-2 h-2 rounded-full transition-all duration-300 
                          ${currentSlide === index ? 'bg-white w-4' : 'bg-white/50'}`}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? <MobileSlideContainer /> : <DesktopSlideContainer />}
      
      {/* Video Modal */}
      {isVideoOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative w-full max-w-3xl p-4">
            <video
              src={appintro}
              controls
              autoPlay
              className="w-full h-auto max-h-[80vh]"
            />
            <button
              onClick={() => setIsVideoOpen(false)}
              className="absolute top-2 right-2 text-white text-2xl"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Animation;