import React, { useState } from 'react';
import ShopNavbar from '../components/ShopNavbar';
import ShopNowHero from '../components/ShopNowHero';
import Footer from '../components/Footer';
import CategorySection from '../components/CategorySection';
import CategoryMenu from '../components/CategoryMenu';
import ProductBanner from '../components/ProductBanner';
import ProductGallery from '../components/ProductGallery';
import BrandsGallery from '../components/BrandsGallery';
import { CategoryProvider } from '../context/CategoryContext';

const ShopNowPage = () => {
  const [selectedCategory, setSelectedCategory] = useState('MEN');

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <CategoryProvider>
      <div className="min-h-screen bg-black overflow-hidden">
        {/* Shop Navbar */}
        <ShopNavbar />
        
        {/* Category Menu with category change handler */}
        <CategoryMenu onCategoryChange={handleCategoryChange} />
        
        {/* Hero Section */}
        <ShopNowHero />

        {/* Brands Gallery with selected category */}
        <BrandsGallery selectedCategory={selectedCategory} />

        {/* Category Section */}
        <CategorySection selectedCategory={selectedCategory} />

        {/* Product Banner */}
        <ProductBanner />

        {/* Product Gallery */}
        <ProductGallery />

        {/* Footer */}
        <Footer />
      </div>
    </CategoryProvider>
  );
};

export default ShopNowPage;
