import React, { useState, useEffect } from "react";
import Navbar from '../components/Navbar';
import ConciergeHome from '../components/ConciergeHome';
import Footer from '../components/Footer';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
// Import icons
import nothingIcon from '../assets/nothing.png';
import brandIcon from '../assets/brand.png';
import customerIcon from '../assets/customer.png';
import { ShoppingBagIcon, SparklesIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import ThankYouPopup from '../components/thankyoupopup';

const ConciergePage = () => {
  const navigate = useNavigate();
  
  // Add FAQ state
  const [openFaq, setOpenFaq] = useState(null);
  const [showThankYou, setShowThankYou] = useState(false);

  useEffect(() => {
    // Check if there's a hash in the URL
    if (window.location.hash === '#contact-us') {
      // Add a small delay to ensure the page is fully loaded
      setTimeout(() => {
        const element = document.getElementById('contact-us');
        if (element) {
          element.scrollIntoView({ 
            behavior: 'smooth',
            block: 'start'
          });
        }
      }, 100);
    }
  }, []);

  // FAQ toggle handler
  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  // Updated FAQ Data
  const faqData = [
    {
      question: "What exactly is Zulu Concierge?",
      answer: "We're your personal shopper, style scout, and deal negotiator—all rolled into one. You tell us what you need, and we hunt it down. Think of us as your shopping genie, minus the magic lamp."
    },
    {
      question: "Who can use this service?",
      answer: "Anyone in NCR looking to save time, skip chaos, and score the right finds. NRIs living here, busy professionals, brides-to-be—Zulu's open to all."
    },
    {
      question: "How do I get started?",
      answer: "Easy. Fill out our short form, ping us on WhatsApp, or even call. Just say 'Help me shop' and we'll take it from there."
    },
    {
      question: "Is there a fee or membership cost?",
      answer: "We believe in zero hidden surprises. Depending on the request, we may charge a small service fee. But don't worry—we'll be upfront so you know exactly what you're paying for."
    },
    {
      question: "What's the typical turnaround time?",
      answer: "It depends on what you're looking for—some items might be ready the next day, while bespoke stuff takes longer. We'll keep you in the loop every step of the way."
    },
    {
      question: "Do you help with custom or one-of-a-kind orders?",
      answer: "Absolutely. Whether it's a bridal lehenga tailored to perfection or handmade furniture, we've got the hookups to make it happen."
    },
    {
      question: "What if I need to return or exchange an item?",
      answer: "We'll do our best to make it smooth. While we can't change store policies, we'll guide you through the process and help negotiate wherever possible."
    },
    {
      question: "Can Zulu also suggest styles or options if I'm clueless?",
      answer: "Yup! We're your personal style wingman. Tell us your vibe—classic, modern, somewhere in between—and we'll serve up the best matches."
    },
    {
      question: "Is there a limit on what I can ask you to find?",
      answer: "Not really. As long as it's legit and available in India, we'll try our hardest to score it for you. Challenge accepted!"
    },
    {
      question: "How safe is my information with you?",
      answer: "We're big on privacy. Your personal details stay locked up with us—no shady data sharing, promise."
    }
  ];

  return (
    <div className="relative w-full bg-white">
      <Navbar />
      <ConciergeHome />

      {/* Why Choose Zulu Section */}
      <div className="py-24 2xl:py-32 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 2xl:max-w-[1400px]">
          {/* Section Header */}
          <div className="text-center space-y-6 mb-16 2xl:mb-20">
            <span className="text-sm 2xl:text-base font-medium text-gray-600 tracking-wider uppercase">
              CONCIERGE
            </span>
            <h2 className="text-3xl md:text-4xl 2xl:text-5xl font-bold text-black">
              We can help you shop from everywhere you want.
            </h2>
            <p className="text-lg 2xl:text-xl text-gray-600 font-light max-w-2xl 2xl:max-w-3xl mx-auto">
              Available across Delhi Legacy markets such as Chandni Chowk, Rajouri, Lajpat Nagar etc.
            </p>
          </div>

          {/* Features Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 2xl:gap-10 max-w-6xl 2xl:max-w-7xl mx-auto">
            {[
              {
                title: "Full day market visits",
                description: "Browse as many outlets as you want",
                icon: ShoppingBagIcon
              },
              {
                title: "One point solution",
                description: "We find, negotiate, deliver & alter",
                icon: SparklesIcon
              },
              {
                title: "Club Membership",
                description: "Use anytime at a monthly fee",
                icon: UserGroupIcon
              }
            ].map((feature, index) => (
              <div 
                key={index}
                className="relative rounded-2xl bg-gray-50 border border-gray-200 
                         backdrop-blur-sm p-8 2xl:p-10"
              >
                <div className="space-y-6 2xl:space-y-8">
                  <div className="w-16 h-16 2xl:w-20 2xl:h-20 rounded-2xl 
                              bg-gray-100 p-3 border border-gray-200
                              shadow-lg">
                    <feature.icon 
                      className="w-full h-full text-gray-800"
                    />
                  </div>

                  <div className="space-y-3 2xl:space-y-4">
                    <h3 className="text-xl 2xl:text-2xl font-semibold text-black">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600 2xl:text-lg leading-relaxed">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Get Started Section */}
      <div id="contact-us" className="bg-white text-black scroll-mt-24">
        <div className="container mx-auto px-4 py-24">
          <div className="flex flex-col md:flex-row justify-between items-center gap-12">
            {/* Left Content */}
            <div className="w-full md:w-1/2 text-center md:text-left">
              <h2 className="text-4xl xl:text-4xl 2xl:text-5xl font-bold mb-7 text-black 2xl:ml-[20%]">
                Get your personal shopping assistance
              </h2>
              <p className="text-xl text-gray-600 mb-10 2xl:ml-[20%]">
                Experience luxury shopping with dedicated concierge service
              </p>
            </div>

            {/* Right Content - Contact Form */}
            <div className="w-full md:w-5/12 2xl:w-4/12 2xl:mr-[5%] bg-gray-50 rounded-2xl p-8 border border-gray-200
                          hover:bg-gray-100 transition-all duration-300">
              <h3 className="text-2xl font-semibold mb-6 text-black">Contact Us</h3>
              <p className="text-gray-600 mb-8">
                Fill in your details and we'll get back to you
              </p>
              <form onSubmit={async (e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const name = formData.get('name');
                const phone = formData.get('phone');
                
                // Validate inputs
                if (!name.trim() || !phone.trim()) {
                  alert('Please fill in all fields');
                  return;
                }

                const phoneRegex = /^\d{10}$/;
                if (!phoneRegex.test(phone.trim())) {
                  alert('Please enter a valid 10-digit phone number');
                  return;
                }

                try {
                  const timestamp = new Date().toISOString()
                    .replace('Z', '+05:30')
                    .slice(0, 16);

                  const data = new URLSearchParams();
                  data.append('name', name.trim());
                  data.append('mobileNumber', phone.trim());
                  data.append('timestamp', timestamp);
                  data.append('Type', 'Concierge');
                  data.append('Source', 'Concierge Name Number');

                  const response = await fetch('https://hooks.zapier.com/hooks/catch/21065465/28s8lvn/', {
                    method: 'POST',
                    mode: 'no-cors',
                    body: data
                  });

                  // Clear form
                  e.target.reset();
                  setShowThankYou(true);

                } catch (error) {
                  console.error('Error:', error);
                  alert('There was an error submitting your request. Please try again.');
                }
              }} 
              className="space-y-6">
                <div className="space-y-2">
                  <label htmlFor="name" className="text-gray-600 text-sm">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter your name"
                    className="w-full px-4 py-3 bg-white border border-gray-200 rounded-lg
                             text-black placeholder-gray-400
                             focus:outline-none focus:border-gray-400
                             transition-colors"
                    required
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="phone" className="text-gray-600 text-sm">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Enter your phone number"
                    className="w-full px-4 py-3 bg-white border border-gray-200 rounded-lg
                             text-black placeholder-gray-400
                             focus:outline-none focus:border-gray-400
                             transition-colors"
                    required
                    pattern="\d{10}"
                  />
                </div>
                <button 
                  type="submit"
                  className="w-full px-8 py-4 bg-black text-white rounded-xl 
                           hover:bg-black/90 transition-all duration-300 
                           text-lg font-medium transform hover:scale-105
                           shadow-lg hover:shadow-xl"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="bg-white py-24 2xl:py-32">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 2xl:max-w-[1400px]">
          {/* Section Header */}
          <div className="text-center space-y-6 mb-16 2xl:mb-20">
            <h2 className="text-3xl md:text-4xl 2xl:text-5xl font-bold text-black">
              Frequently Asked Questions
            </h2>
            <p className="text-lg 2xl:text-xl text-gray-600 font-light max-w-2xl 2xl:max-w-3xl mx-auto">
              Everything you need to know about Zulu Concierge service
            </p>
          </div>

          {/* FAQ Items */}
          <div className="max-w-3xl mx-auto space-y-4">
            {faqData.map((faq, index) => (
              <div 
                key={index}
                className="border border-gray-200 rounded-xl overflow-hidden
                         bg-gray-50 hover:bg-gray-100
                         transition-all duration-300"
              >
                <button
                  className="w-full px-6 py-4 sm:px-8 sm:py-6 flex items-center justify-between
                           text-left transition-all duration-300"
                  onClick={() => toggleFaq(index)}
                >
                  <span className="text-lg text-black font-medium pr-8">
                    {faq.question}
                  </span>
                  <ChevronDownIcon 
                    className={`w-6 h-6 text-gray-600 transition-transform duration-300
                              ${openFaq === index ? 'transform rotate-180' : ''}`}
                  />
                </button>
                
                {openFaq === index && (
                  <div className="px-6 pb-4 sm:px-8 sm:pb-6">
                    <p className="text-gray-600 leading-relaxed">
                      {faq.answer}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />

      <ThankYouPopup 
        isOpen={showThankYou} 
        onClose={() => setShowThankYou(false)} 
      />
    </div>
  );
};

export default ConciergePage;
