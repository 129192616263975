import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const ContactSalesPage = () => {
  // Add useEffect to scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Create Indian timestamp
      const indianTimestamp = new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });

      const jsonData = {
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        message: formData.message,
        timestamp: indianTimestamp,
        type: 'Business'
      };

      const response = await fetch('https://hooks.zapier.com/hooks/catch/21065465/28s8lvn/', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData)
      });

      console.log('Form submitted successfully');
      
      // Reset form
      setFormData({
        name: '',
        phone: '',
        email: '',
        message: ''
      });

      // Show success message
      alert('Thank you for your message! Our team will contact you soon.');

    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting your form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-black">
      <Helmet>
        <title>Contact Us - Zulu</title>
      </Helmet>
      
      <Navbar />
      
      <div className="py-12 sm:py-16 md:py-20 lg:py-24 translate-y-[4%] xl:translate-y-[5%] 2xl:translate-y-[5%]">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header */}
          <div className="text-center mb-12 md:mb-16">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold 
                         bg-gradient-to-r from-white via-gray-100 to-white 
                         bg-clip-text text-transparent mb-4">
              Contact Us
            </h1>
            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
              Fill your details and our team will reach out to you at the earliest
            </p>
          </div>

          {/* Contact Form */}
          <div className="max-w-2xl mx-auto">
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Name */}
              <div>
                <label htmlFor="name" className="block text-white mb-2">
                  Name *
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 
                           rounded-lg text-white focus:outline-none 
                           focus:border-white/20 transition-colors"
                  placeholder="Enter your name"
                />
              </div>

              {/* Phone */}
              <div>
                <label htmlFor="phone" className="block text-white mb-2">
                  Phone *
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 
                           rounded-lg text-white focus:outline-none 
                           focus:border-white/20 transition-colors"
                  placeholder="Enter your phone number"
                />
              </div>

              {/* Email */}
              <div>
                <label htmlFor="email" className="block text-white mb-2">
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 
                           rounded-lg text-white focus:outline-none 
                           focus:border-white/20 transition-colors"
                  placeholder="Enter your email"
                />
              </div>

              {/* Message */}
              <div>
                <label htmlFor="message" className="block text-white mb-2">
                  Message *
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows={4}
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 
                           rounded-lg text-white focus:outline-none 
                           focus:border-white/20 transition-colors"
                  placeholder="Enter your message"
                />
              </div>

              {/* Submit Button */}
              <div className="flex justify-center pt-6">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-8 py-3 bg-white text-black rounded-lg 
                           transform transition-all duration-300 
                           hover:scale-105 hover:bg-white/90
                           font-medium text-lg
                           disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactSalesPage; 