import React, { useState, useEffect } from 'react';
import videoTourVideo from '../assets/videotour1.mp4'; // Update with your video tour image
import DownloadAppPopup from './DownloadAppPopup';

const RunVideoTour = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Mobile Layout
  const MobileLayout = () => (
    <div className="bg-white py-6 sm:py-8 px-4">
      <div className="container mx-auto">
        {/* Mobile Text Content */}
        <div className="text-center mb-8">
          <div className="p-6">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 mb-3 sm:mb-4">
              Shop live on video <br /> from your home
            </h2>
            
            <p className="text-base sm:text-lg md:text-xl text-gray-700 mb-4 sm:mb-6 leading-relaxed">
              Explore the latest in store collection with Zulu shoppers in the market
            </p>

            <button 
              className="bg-gray-900 text-white font-semibold 
                       py-2 sm:py-2.5 px-4 sm:px-6
                       rounded-lg border border-gray-700
                       transition-all duration-300 ease-in-out
                       text-sm sm:text-base"
              onClick={() => setIsOpen(true)}
            >
              Get Started
            </button>
          </div>
        </div>

        {/* Mobile Video Card - Fixed Centering */}
        <div className="flex justify-center items-center w-full">
          <div className="w-[280px] sm:w-[300px] md:w-[320px]">
            <div className="aspect-[3/4] relative
                          rounded-xl overflow-hidden
                          bg-white/5 backdrop-blur-sm border border-white">
              <video
                src={videoTourVideo}
                alt="Video Tour"
                className="w-full h-full object-contain"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Return either mobile or desktop layout
  return (
    <>
      {isMobile ? (
        <MobileLayout />
      ) : (
        // Desktop Layout
        <div className="flex xl:h-[90vh] xl:translate-y-[-10%] 2xl:translate-y-[5%]">
          {/* Left Dark Section - 50% */}
          <div className="w-1/2 bg-white min-h-screen flex items-center justify-end translate-x-[5%]">
            <div className="w-full lg:w-[600px] 2xl:w-[800px] pr-20 2xl:pr-32">
              {/* Video Content */}
              <div className="relative aspect-[3/4] w-[300px] 2xl:w-[400px] 
                            h-[500px] 2xl:h-[640px] 
                            translate-x-[30%] 2xl:translate-x-[40%]">
                <div className="relative w-full h-full rounded-2xl overflow-hidden 
                              bg-white/5 backdrop-blur-sm border border-white">
                  <video
                    src={videoTourVideo}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="w-full h-full object-contain"
                  />
                  <div className="" />
                </div>
              </div>
            </div>
          </div>

          {/* Right White Section - 50% */}
          <div className="w-1/2 bg-white min-h-screen flex items-center translate-x-[5%]">
            <div className="w-full  lg:w-[600px] 2xl:w-[800px] pl-20 2xl:pl-32">
              <div className="space-y-8 2xl:space-y-12">
                {/* Title with refined styling */}
                <h2 className="text-5xl 2xl:text-6xl font-bold text-gray-900 leading-tight translate-y-[-30%]">
                  Shop live on video <br></br> from your home
                </h2>
                
                {/* Description with refined styling */}
                <p className="text-xl 2xl:text-2xl text-gray-600 leading-relaxed font-poppins 
                            max-w-xl 2xl:max-w-2xl tracking-wide translate-y-[-25%]">
                  Explore the latest in store collection <br></br> with Zulu shoppers in the market
                </p>

                {/* Modern button design */}
                <div className="pt-4 translate-y-[70%]">
                  <button 
                    className="group relative px-8 2xl:px-10 py-3.5 2xl:py-4
                             overflow-hidden rounded-xl 
                             bg-gray-900 hover:bg-gray-800
                             transition-all duration-300 ease-out
                             shadow-lg hover:shadow-xl"
                    onClick={() => setIsOpen(true)}
                  >
                    <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-white/0 via-white/5 to-white/0 
                                  translate-x-[-100%] group-hover:translate-x-[100%] 
                                  transition-transform duration-700" />
                    <span className="relative font-medium text-lg 2xl:text-xl text-white tracking-wide">
                      Get Started
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DownloadAppPopup isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default RunVideoTour; 