import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCategory } from '../context/CategoryContext';
import { fetchCategories } from '../services/api';

const CategorySection = ({selectedCategory}) => {
  const { activeCategory } = useCategory();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  
  const getAllChildren = (categoryName, categoryList) => {
    // Find the category with the given name
    const category = categoryList.find(cat => cat.name.toUpperCase() === categoryName.toUpperCase());
    if (!category) return [];  // Return empty array if not found
    console.log('Category:', category);
    let allChildren = [];

    for (const cat of category.children) {  // Fix iteration syntax
        console.log('Cat:', cat);
        
            if (cat.children && cat.children.length > 0) {
                allChildren.push(...cat.children);  // Fix addAll (JS uses push(...))
                console.log('All Children:', allChildren);  // Recursively collect children
            }
        

          // Call the function for each child
    }
    setCategories(allChildren);
    return allChildren;
};

  useEffect(() => {
    const loadCategories = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await fetchCategories();
        
        // Log the response data directly
        // console.log('API Response:', response);
        // console.log('Data length:', response.data.length);
        // console.log('Categories:', response.data);

        setCategories(response);
        
      } catch (err) {
        console.error('Error in loadCategories:', err);
        setError(err.message || 'Failed to load categories');
      } finally {
        setLoading(false);
      }
    };

    loadCategories();
  }, []);

  // If you want to see when categories state updates, use another useEffect
  useEffect(() => {
    // console.log('Categories state updated:', categories.length);
    // console.log('Categories data:', categories);
    const children = getAllChildren(selectedCategory, categories);
    
  }, [categories]); // This will run whenever categories state changes
  

  if (loading) {
    return (
      <section className="py-8 md:py-16 bg-black">
        <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-center min-h-[300px]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="py-8 md:py-16 bg-black">
        <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center justify-center min-h-[300px]">
            <p className="text-red-500 text-center mb-4">{error}</p>
            <button 
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-white/10 hover:bg-white/20 text-white rounded-lg
                       transition-colors duration-200"
            >
              Try Again
            </button>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="py-8 md:py-16 bg-white">
      <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-black mb-6 md:mb-8 text-center">
          {activeCategory.charAt(0).toUpperCase() + activeCategory.slice(1)}'s Categories
        </h2>
        
        {/* Mobile Scrollable View */}
        <div className="md:hidden overflow-x-auto pb-4 -mx-4 px-4">
          <div className="flex space-x-4">
            {categories.map((category) => (
              <div
                key={category.id}
                className="flex-none w-[160px] group relative aspect-[3/4] overflow-hidden rounded-xl"
              >
                <div className="absolute inset-0">
                  <img
                    src={category.image_url || category.image}
                    alt={category.name}
                    className="w-full h-full object-cover transition-transform duration-500 
                             group-hover:scale-110"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = 'https://via.placeholder.com/300x400?text=Image+Not+Found';
                    }}
                  />
                  <div className="absolute inset-0 bg-black/40 group-hover:bg-black/50 
                              transition-colors duration-300" />
                </div>
                
                <div className="absolute inset-0 flex items-center justify-center p-2">
                  <h3 className="text-white text-sm font-semibold tracking-wider
                               px-2 py-1 rounded-full border border-white/30 
                               bg-black/30 backdrop-blur-sm
                               transform transition-all duration-300
                               group-hover:scale-110 group-hover:bg-black/50
                               text-center whitespace-nowrap">
                    {category.name}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Desktop Grid View */}
        <div className="hidden md:grid grid-cols-4 lg:grid-cols-8 gap-4">
          {categories.map((category) => (
            <div
              key={category.id}
              className="group relative aspect-[3/4] overflow-hidden rounded-xl"
            >
              <div className="absolute inset-0">
                <img
                  src={category.image_url || category.image}
                  alt={category.name}
                  className="w-full h-full object-cover transition-transform duration-500 
                           group-hover:scale-110"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'https://via.placeholder.com/300x400?text=Image+Not+Found';
                  }}
                />
                <div className="absolute inset-0 bg-black/40 group-hover:bg-black/50 
                            transition-colors duration-300" />
              </div>
              
              <div className="absolute inset-0 flex items-center justify-center p-2">
                <h3 className="text-white text-sm font-semibold tracking-wider
                             px-2 py-1 rounded-full border border-white/30 
                             bg-black/30 backdrop-blur-sm
                             transform transition-all duration-300
                             group-hover:scale-110 group-hover:bg-black/50
                             text-center whitespace-nowrap">
                  {category.name}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CategorySection;
