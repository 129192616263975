import React from 'react';
import offerimage from '../assets/500off.png';

const DownloadAppPopup = ({ isOpen, onClose }) => {
  // Simple conditional render
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/40 backdrop-blur-md flex items-center justify-center z-[9999]"
         onClick={(e) => {
           if (e.target === e.currentTarget) onClose();
         }}>
      <div className="bg-black/80 backdrop-blur-xl border-2 border-red-900/30 rounded-xl 
                    p-3 sm:p-8 
                    w-[75%] sm:w-[450px] mx-2 sm:mx-4
                    min-h-[350px] sm:min-h-[520px] max-h-[95vh] overflow-y-auto
                    transform transition-all duration-500 hover:scale-[1.02]
                    hover:shadow-[0_0_50px_rgba(255,0,0,0.2)] relative">
        <button
          onClick={onClose}
          className="absolute top-2 sm:top-4 right-2 sm:right-4 text-xl sm:text-2xl text-white/70 hover:text-white 
                    transition-colors duration-200"
        >
          ×
        </button>

        <div className="space-y-2 sm:space-y-4 h-full flex flex-col">
          <h3 className="text-xl sm:text-2xl font-bold text-white/90 text-left pl-2">
            Download App
          </h3>
          
          <img 
            src={offerimage} 
            alt="Offer" 
            className="w-[85%] sm:w-[400px] mx-auto object-contain flex-shrink-0"
          />
          
          <div className="flex flex-col gap-6 sm:gap-3 
                        mt-auto pb-2 sm:pb-0
                        transform translate-y-0">
            <a 
              href="https://play.google.com/store/apps/details?id=com.zulu.consumer.zulu_consumer"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-1.5 sm:gap-2 
                       bg-white/10 backdrop-blur-sm border border-red-900/20 sm:border-2 
                       rounded-lg px-2 sm:px-6 py-1 sm:py-2
                       w-[90%] sm:w-[100%] mx-auto
                       hover:bg-white/20 hover:border-red-800/40
                       hover:shadow-[0_4px_16px_rgba(255,0,0,0.25)]
                       transition-all duration-300"
            >
              <svg className="w-6 h-6 sm:w-10 sm:h-10 text-white" viewBox="0 0 24 24" fill="currentColor">
                <path d="M3,20.5V3.5C3,2.91 3.34,2.39 3.84,2.15L13.69,12L3.84,21.85C3.34,21.6 3,21.09 3,20.5M16.81,15.12L6.05,21.34L14.54,12.85L16.81,15.12M20.16,10.81C20.5,11.08 20.75,11.5 20.75,12C20.75,12.5 20.5,12.92 20.16,13.19L17.89,14.5L15.39,12L17.89,9.5L20.16,10.81M6.05,2.66L16.81,8.88L14.54,11.15L6.05,2.66Z"/>
              </svg>
              <div className="text-white/90 flex flex-col items-start">
                <span className="text-[10px] sm:text-xs text-center">Get it on</span>
                <span className="text-base sm:text-xl font-medium">Google Play</span>
              </div>
            </a>
            
            <a 
              href="https://apps.apple.com/in/app/zulu-club/id6739531325"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-1.5 sm:gap-2
                       bg-white/10 backdrop-blur-sm border border-red-900/20 sm:border-2
                       rounded-lg px-2 sm:px-6 py-1 sm:py-2
                       w-[90%] sm:w-[100%] mx-auto
                       hover:bg-white/20 hover:border-red-800/40
                       hover:shadow-[0_4px_16px_rgba(255,0,0,0.25)]
                       transition-all duration-300"
            >
              <svg className="w-6 h-6 sm:w-10 sm:h-10 text-white -ml-2 sm:-ml-5" viewBox="0 0 24 24" fill="currentColor">
                <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11"/>
              </svg>
              <div className="text-white/90 flex flex-col items-start">
                <span className="text-[10px] sm:text-xs text-center">Download on the</span>
                <span className="text-base sm:text-xl font-medium">App Store</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppPopup; 